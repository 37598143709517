import bootstrap from '../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';
import template from './InmateInformation.html';
import { FeatureFlag } from "../types/Library/Common/Enumerations/FeatureFlag";

export class InmateInformation extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap], ['container']);

        this._inmateFullNameInput = this.shadowRoot.getElementById('inmate-full-name');
        this._soNumberInput = this.shadowRoot.getElementById('so-number');
        this._dateOfBirthInput = this.shadowRoot.getElementById('date-of-birth');
        this._raceGenderInput = this.shadowRoot.getElementById('race-gender');
        this._mugshotCarousel = this.shadowRoot.getElementById('mugshot-carousel');
        this._errorMessage = this.shadowRoot.getElementById('error-message');
    }

    set userIsCounty(value) {
        this._userIsCounty = value;
    }

    loadInmateInformationByBondApplicationId(bondApplicationId) {
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            'GET',
            `/Inmate/InformationByBondApplicationId/${bondApplicationId}`,
            null,
            this._getInmateInformationCallback.bind(this, null));
    }

    loadInmateInformationBySoNumber(soNumber) {
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            'GET',
            `/Inmate/InformationBySoNumber?id=${encodeURIComponent(soNumber)}`,
            null,
            this._getInmateInformationCallback.bind(this, null));
    }

    _getInmateInformationCallback(tenantId, response, status) {
        if (!status) {
            this._errorMessage.toggleAttribute('hidden', false);
            return;
        }
        const result = JSON.parse(response);

        if (this._userIsCounty && settings.featureFlags[FeatureFlag.HideMugshotsForJailAccounts] || !this._userIsCounty &&  settings.featureFlags[FeatureFlag.HideMugshotsForCompanyAccounts]) {
            this._mugshotCarousel.toggleAttribute('hidden', true);
        }
        else {
            this._mugshotCarousel.toggleAttribute('hidden', false);
        }
        this._inmateFullNameInput.value = `${result.inmateLn ?? ''}, ${result.inmateFn ?? ''} ${result.inmateMi ?? ''}`.trim();
        this._soNumberInput.value = result.soNumber;
        this._dateOfBirthInput.value = result.dob;
        this._raceGenderInput.value = `${result.race} / ${result.gender}`;
        if (tenantId) {
            this._mugshotCarousel.tenantId = tenantId;
        }
        this._mugshotCarousel.tenantName = result.tenantName;
        this._mugshotCarousel.mugshots = result.mugshots;
    }
}
customElements.define('inmate-information', InmateInformation);