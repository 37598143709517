import { FeatureFlag } from "../../types/Library/Common/Enumerations/FeatureFlag";
import fontawesome from "@fortawesome/fontawesome-free/css/all.css";
import bootstrap from "../../scss/bootstrap-custom.scss";
import { clearAlert, saveDialog, showAlert } from "../EditDialogExtensions";
import { initializeHtmlElement } from "../HTMLElementExtensions";
import template from "./EditTenantEmailDialog.html";

class EditTenantEmailDialog extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template, [bootstrap, fontawesome]);
        this._mutationObserver = null;
        this._pageTools = new PageTools();
        this._form = this.shadowRoot.getElementById("modal-content-group");
        this._modal = this.shadowRoot.querySelector("modal-component");
        this._alertContainer = this.shadowRoot.getElementById("alert-container");
        this._alert = this.shadowRoot.getElementById("modal-alert-view");
        this._closeButton = this.shadowRoot.getElementById("edit-tenant-email-dialog-close-button");
        this._saveButton = this.shadowRoot.getElementById("edit-tenant-email-dialog-save-button");
        this._emailInput = this.shadowRoot.getElementById("email-input");
        this._cashBondsReadyContainer = this.shadowRoot.getElementById("cash-bonds-ready-for-review-container");
        this._cashBondsReadyCheckbox = this.shadowRoot.getElementById("cash-bonds-ready-for-review-checkbox");
        this._BondSubmittedCheckBoxContainer = this.shadowRoot.getElementById("bond-submitted-checkbox-container");
        this._BondSubmittedCheckBox = this.shadowRoot.getElementById("bond-submitted-checkbox");
        this._BondReviewedCheckBoxContainer = this.shadowRoot.getElementById("bond-reviewed-checkbox-container");
        this._BondReviewedCheckBox = this.shadowRoot.getElementById("bond-reviewed-checkbox");
        this._postedOrCancelledContainer = this.shadowRoot.getElementById("posted-or-cancelled-bond-applications-container");
        this._postedOrCancelledCheckbox = this.shadowRoot.getElementById("posted-or-cancelled-bond-applications-checkbox");
        this._dataEntryErrorsDetectedContainer = this.shadowRoot.getElementById("data-entry-errors-detected-container");
        this._dataEntryErrorsDetectedCheckbox = this.shadowRoot.getElementById("data-entry-errors-detected-checkbox");

        this._dataEntryTooltip = this.shadowRoot.getElementById("data-entry-error-tooltip");

        this._closeModal = this._closeModal.bind(this);
        this._saveDialog = this._saveDialog.bind(this);
        this._observerCallback = this._observerCallback.bind(this);
    }

    connectedCallback() {
        $(this._dataEntryTooltip).tooltip({ boundary: "window", placement: "auto" });
        this._mutationObserver = new MutationObserver(this._observerCallback);
        this._mutationObserver.observe(this._alert, { attributes: true, attributeOldValue: true, attributeFilter: ["class"] });
        this._closeButton.addEventListener("click", this._closeModal);
        this._saveButton.addEventListener("click", this._saveDialog);
    }

    disconnectedCallback() {
        this._mutationObserver.disconnect();
        this._closeButton.removeEventListener("click", this._closeModal);
        this._saveButton.removeEventListener("click", this._saveDialog);
    }

    openModal(viewModel) {
        let title = "Add Email";
        this._saveUrl = "/Admin/AddEmail";
        this._tenantEmailId = null;
        this._isEdit = false;
        this._setInitialValues(viewModel);
        if (viewModel) {
            title = "Edit Email";
            this._saveUrl = "/Admin/EditEmail";
            this._tenantEmailId = viewModel.TenantEmailId;
            this._isEdit = true;
        }

        this._showOrHideCheckboxes();
        clearAlert(this._alert);
        this._modal.openModal(title, false);
    }

    _showOrHideCheckboxes() {
        this._cashBondsReadyContainer.toggleAttribute("hidden", !(settings.featureFlags[FeatureFlag.AcceptsAllPaidPayments]));
        this._BondSubmittedCheckBoxContainer.toggleAttribute("hidden", !settings.featureFlags[FeatureFlag.SendEmailToJailUsersOnApplicationSubmittedOrReviewedTemp]);
        this._BondReviewedCheckBoxContainer.toggleAttribute("hidden", !settings.featureFlags[FeatureFlag.SendEmailToJailUsersOnApplicationSubmittedOrReviewedTemp]);
        this._dataEntryErrorsDetectedContainer.toggleAttribute("hidden", !settings.featureFlags[FeatureFlag.NotifyTenantOfDataProcessingIssuesTemp]);
    }

    _observerCallback(mutations, observer) {
        const classMutation = mutations.find(x => x.type === "attributes" && x.attributeName === "class");
        this._alertContainer.toggleAttribute("hidden", classMutation.target.classList.contains("d-none"));
    }

    _closeModal() {
        this._modal.closeModal();
    }

    _saveDialog() {
        const data = this._gatherData();
        saveDialog({
            dialogElement: this,
            modalComponent: this._modal,
            form: this._form,
            alertElement: this._alert,
            url: this._saveUrl,
            data: data,
            pageTools: this._pageTools,
            useGenericErrorMessage: false,
            customSaveCallback: this._saveCallback.bind(this, data.email)
        });
    }

    _saveCallback(emailAddress, response, status) {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        const refreshEvent = new CustomEvent("refreshGrid", { detail: null });
        if (!status) {
            showAlert(this._alert, response);
            this.dispatchEvent(refreshEvent);
            return;
        }
        const saveEvent = new CustomEvent("save", { detail: { email: emailAddress, isEdit: this._isEdit } });
        this.dispatchEvent(refreshEvent);
        this.dispatchEvent(saveEvent);
        this._closeModal();
    }

    _gatherData() {
        const email = this._emailInput.value;
        const cashBondReadyForReview = this._cashBondsReadyCheckbox.checked;
        const bondSubmitted = this._BondSubmittedCheckBox.checked;
        const bondReviewed = this._BondReviewedCheckBox.checked;
        const postedOrCancelledBondApplication = this._postedOrCancelledCheckbox.checked;
        const dataEntryErrorsDetected = this._dataEntryErrorsDetectedCheckbox.checked;
        
        return {
            tenantEmailId: this._tenantEmailId,
            email: email,
            cashBondReadyForReview: cashBondReadyForReview,
            bondSubmitted: bondSubmitted,
            bondReviewed: bondReviewed,
            postedOrCancelledBondApplication: postedOrCancelledBondApplication,
            dataEntryErrorsDetected: dataEntryErrorsDetected
        };
    }

    _setInitialValues(viewModel) {
        if (viewModel) {
            this._emailInput.value = viewModel.Email;
            this._cashBondsReadyCheckbox.checked = viewModel.CashBondReadyForReview;
            this._BondSubmittedCheckBox.checked = viewModel.BondSubmitted;
            this._BondReviewedCheckBox.checked = viewModel.BondReviewed;
            this._postedOrCancelledCheckbox.checked = viewModel.PostedOrCancelledBondApplication;
            this._dataEntryErrorsDetectedCheckbox.checked = viewModel.DataEntryErrorsDetected;
        } else {
            this._emailInput.value = null;
            this._cashBondsReadyCheckbox.checked = false;
            this._BondSubmittedCheckBox.checked = false;
            this._BondReviewedCheckBox.checked = false;
            this._postedOrCancelledCheckbox.checked = false;
            this._dataEntryErrorsDetectedCheckbox.checked = false;
        }
    }
}
customElements.define("edit-tenant-email-dialog", EditTenantEmailDialog);