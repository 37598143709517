import template from './AmendBondDocumentModal.html';
import bootstrap from "../../scss/bootstrap-custom.scss";
import { initializeHtmlElement } from '../HTMLElementExtensions';
import { nullThrow } from '../TypeScriptFunctions';
import { ModalComponent } from '../ModalComponent.js'
import { ActionButton } from "../ActionButton"
import { FileUploadComponent } from "../FileUploadComponent"
import { FileStatusDetail } from "../../types/Library/Common/CustomEvents/CustomEvents";

export class AmendBondDocumentModal extends HTMLElement
{
    private readonly _modal: ModalComponent;
    private readonly _saveButton: ActionButton;
    private readonly _previewButton: ActionButton;
    private readonly _canceButton: ActionButton;
    private readonly _fileUpload: FileUploadComponent;
    private readonly _routePath: string;
    private readonly _pageTools: IPageTools;
    private _bondApplicationDetailId: number | undefined;
    constructor()
    {
        super();
        initializeHtmlElement(this, template, [bootstrap]);
        this._pageTools = new PageTools;
        this._modal = <ModalComponent>nullThrow(this.shadowRoot?.getElementById("modal-component"));
        this._saveButton = <ActionButton>nullThrow(this.shadowRoot?.getElementById("save-button"));
        this._previewButton = <ActionButton>nullThrow(this.shadowRoot?.getElementById("preview-button"));
        this._canceButton = <ActionButton>nullThrow(this.shadowRoot?.getElementById("cancel-button"));
        this._fileUpload = <FileUploadComponent>nullThrow(this.shadowRoot?.getElementById("file-upload"));

        this._routePath = "/Reports/AmendBondDocument";

        this.closeModal = this.closeModal.bind(this);
        this._saveUploadDocuments = this._saveUploadDocuments.bind(this);
        this._uploadFilesCallback = this._uploadFilesCallback.bind(this);
        this._printPreviewOnClick = this._printPreviewOnClick.bind(this);
        this._printPreviewCallback = this._printPreviewCallback.bind(this);
        this._saveState = this._saveState.bind(this);
        this._checkFileValidity = this._checkFileValidity.bind(this);

        this._saveButton.addEventListener('click', () => this._saveUploadDocuments());
        this._previewButton.addEventListener('click', () => this._printPreviewOnClick());
        this._canceButton.addEventListener('click', this.closeModal);
        this._fileUpload.addEventListener('fileStatusDetail', (event: Event) => this._saveState(event as CustomEvent<FileStatusDetail>));
        this._fileUpload.addEventListener('checkFiles', (event: Event) => this._checkFileValidity(event as CustomEvent));
        this._modal.addEventListener('modalClosed', (event: Event) => this._saveState(event as CustomEvent<FileStatusDetail>));
    }

    openModal(id: number) {
        this._fileUpload.removeAllFiles();
        this._bondApplicationDetailId = id;
        this._modal.openModal("Amend Bond Document", false);
        this._fileUpload.createListOfAcceptedFiles();
        this._fileUpload.clearAlerts();
        this._printPreviewButtonSetup();
        this._fileUpload.hideSpinner(true);
        this._pageTools.toggleTriggers(this.shadowRoot!, false);
        this._saveButton.toggleAttribute("disabled", true);
        this._previewButton.toggleAttribute("disabled", true);
        this._fileUpload.clearButtonDisabled(false);
    }

    closeModal() {
        this._modal.closeModal();
        this._fileUpload.removeAllFiles();
    }

    _saveUploadDocuments() {
        this._fileUpload.clearButtonDisabled(true);
        const formData = this._generateFormData();
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeFormRequest("POST",
            this._routePath,
            formData,
            this._uploadFilesCallback);
    }

    _printPreviewButtonSetup() {
        const printPreviewButtons = this.querySelectorAll("action-button[detailid]");
        Array.from(printPreviewButtons).forEach(button => button.addEventListener("click", this._printPreviewOnClick));
    }

    _printPreviewOnClick() {
        this._fileUpload.clearButtonDisabled(true);
        const formData = this._generateFormData();
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeFormRequest("POST",
            `/Reports/PreviewAmendBondDocument`,
            formData,
            this._printPreviewCallback);
    }

    _printPreviewCallback(response: string, success: boolean) {
        this._fileUpload.clearButtonDisabled(false);
        this._pageTools.toggleTriggers(this.shadowRoot!, false);

        if (!success) {
            this._failureError(response);
            return;
        }

        const byteArrayParsed = this._pageTools.tryParseJson(response);
        const b64blob = this._pageTools.b64toBlob(byteArrayParsed, "application/pdf")
        const linkToPreview = this._createPdfAnchor(b64blob);
        linkToPreview.click();
    }

    _createPdfAnchor(file: File) {
        const elem = document.createElement("a");
        elem.setAttribute("href", URL.createObjectURL(file));
        elem.setAttribute("target", "_blank");
        return elem;
    }

    _generateFormData() {
        const filesList = this._fileUpload.saveFiles();
        this._canceButton.setAttribute("disabled", "true");
        const applicationDetailId = nullThrow(this._bondApplicationDetailId);
        const formData = new FormData();
        formData.append("Id", applicationDetailId.toString());
        filesList.forEach((file) => {
            formData.append("Files", file);
        });
        return formData;
    }

    _uploadFilesCallback(response: string, success: boolean) {
        this._fileUpload.clearButtonDisabled(false);
        this._pageTools.toggleTriggers(this.shadowRoot!, true);

        if (!success) {
            this._failureError(response);
            return;
        }

        // emit an event to trigger table refresh to show amended date
        const amendEvent = new CustomEvent('documentAmended', {
            detail: { message: 'Documents were successfully amended.' }
        });
        this.dispatchEvent(amendEvent);

        this.closeModal();
    }

    _failureError(response: string) {
        if (response.includes("page limit for the eBONDS amendment process")) {
            this._fileUpload.displayAlert(response);
        } else {
            this._fileUpload.displayAlert("There was a problem uploading one or more of your documents.");
        }
        this._pageTools.toggleTriggers(this.shadowRoot!, false);
    }

    _checkFileValidity(event: CustomEvent) {
        if (event.detail.incoming.length + event.detail.current.length > 25) {
            this._fileUpload.displayAlert(
                `The total of selected files exceeds the 25-page limit for the eBONDS amendment process. Please limit the selected documents to a combined total of no more than 25 pages and try again.`
            );
            this._fileUpload.hideSpinner(true);
        } else {
            this._fileUpload.clearAlerts();
        }
    }
    
    _saveState(event: CustomEvent<FileStatusDetail>) {
        if (event.detail.flag) {
            this._pageTools.toggleTriggers(this.shadowRoot!, false);
        }
        else {
            this._pageTools.toggleTriggers(this.shadowRoot!, true);
        }
        this._canceButton.toggleAttribute("disabled", false);
    }

}
customElements.define('amend-bond-document-modal', AmendBondDocumentModal);