import "bootstrap";
import './EditBondGrid';
import bootstrap from './../../scss/bootstrap-custom.scss';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import template from "./EditBondIndex.html";
import { clearAlert, showAlert } from '../EditDialogExtensions';
import { initializeHtmlElement } from "../HTMLElementExtensions";
import { nullThrow } from "../TypeScriptFunctions";
import { ActionButton } from "../ActionButton";
import { AlertMessage } from "../AlertMessage";
import { InmateInformation } from './../InmateInformation';
import type { BondEditPageViewModel } from '../../types/Website/Bonds/Areas/Application/Models/BondEditPageViewModel';
import type { EditBondGrid } from './EditBondGrid';
import { ErrorDetailObject } from "./ErrorDetailObject";

export class EditBondIndex extends HTMLElement {
    private readonly _pageTools: IPageTools;
    private readonly _tooltip: HTMLElement;
    private readonly _searchButton:  ActionButton;
    private readonly _bookingNumberInput: HTMLInputElement;
    private readonly _alertElement: AlertMessage;
    private readonly _inmateInformation: InmateInformation;
    private readonly _editBondGrid: EditBondGrid;

    constructor() {
        super();
        this._pageTools = new PageTools();
        initializeHtmlElement(this, template, [bootstrap, fontawesome], ['container']);
        this._tooltip = nullThrow(this.shadowRoot?.getElementById('title-tooltip'));
        this._searchButton = <ActionButton>nullThrow(this.shadowRoot?.getElementById('search-button'))
        this._bookingNumberInput = <HTMLInputElement>nullThrow(this.shadowRoot?.getElementById('booking-number'));
        this._alertElement = <AlertMessage>nullThrow(this.shadowRoot?.getElementById("alert-message"));
        this._inmateInformation = <InmateInformation>nullThrow(this.shadowRoot?.getElementById('inmate-information'));
        this._editBondGrid = <EditBondGrid>nullThrow(this.shadowRoot?.getElementById('edit-bond-grid'));

        this._searchButton.addEventListener('click', () => this._searchInmate());
        this._editBondGrid.addEventListener('edit-error', (event) => this._handleEditErrorMessage(event));

        this._searchRequestCallback = this._searchRequestCallback.bind(this);
        this._updateHtml = this._updateHtml.bind(this)
        this._inputOnKeyUp = this._inputOnKeyUp.bind(this);
    }

    connectedCallback() {
        $(this._tooltip).tooltip({ boundary: 'window', placement: 'auto' });
        this._bookingNumberInput.addEventListener('keyup', this._inputOnKeyUp);
    }

    disconnectedCallback() {
        $(this._tooltip).tooltip('dispose');
        this._bookingNumberInput.removeEventListener('keyup', this._inputOnKeyUp);
    }

    _inputOnKeyUp(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this._searchButton.click();
        }
    }

    _searchInmate() {
        clearAlert(this._alertElement);
        this._inmateInformation.toggleAttribute('hidden', true);
        this._editBondGrid.toggleAttribute('hidden', true);
        const searchString = this._bookingNumberInput.value.trim();
        if (searchString) {
            this._searchButton.disabled = true;
            const xhrWrapper = new XhrWrapper();
            xhrWrapper.makeRequest('GET',
                `/Application/Edit/GetEditableBonds/${encodeURIComponent(searchString)}`,
                null,
                this._searchRequestCallback
            );
        }
    }

    _searchRequestCallback(response: string, isSuccess: boolean) {
        this._pageTools.toggleTriggers(this.shadowRoot!, false);
        if (!isSuccess) {
            showAlert(this._alertElement, 'Failed to retrieve Bond Application. If this error persists contact your administrator.');
            return;
        }
        const model = <BondEditPageViewModel>this._pageTools.tryParseJson(response);
        if (model.BondApplications.length === 0) {
            const helpLink = `<a href="/Help#editing" target="_blank" rel="noopener noreferrer">See help page regarding editable applications.</a>`;
            showAlert(this._alertElement, `Failed to retrieve Bond Application. Application may not exist, may be canceled, or may not otherwise be eligible for editing. ${helpLink}`);
            return;
        }
        this._updateHtml(model);
    }

    _updateHtml(models: BondEditPageViewModel) {
        this._inmateInformation.loadInmateInformationByBondApplicationId(models.BondApplications[0].BondApplicationId);
        this._inmateInformation.toggleAttribute('hidden', false);
        this._editBondGrid.data = models;
        this._editBondGrid.toggleAttribute('hidden', false);
    }

    _handleEditErrorMessage(event: Event) {
        const customEvent = <CustomEvent<ErrorDetailObject>>event;
        const message = customEvent.detail.error
        if (message != '')
            showAlert(this._alertElement, message);
        else clearAlert(this._alertElement);
    }
}

customElements.define("edit-bond-index", EditBondIndex);