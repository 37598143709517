/* This file is meant to eventually become the Typescript version of DateFormatter.js
    The methods from that file will eventually need to be moved here.
*/
import { nullThrow } from "../TypeScriptFunctions";

export class DateTimeFormatter {
    private readonly moment: Moment;
    private readonly _outputFormat: string;
    private readonly _inputFormat: string[];

    constructor() {
        this.moment = window.moment;
        this._outputFormat = "M/D/YYYY h:mm:ss A";
        this._inputFormat = [
            "M/D/YYYY h:mm:ss A",
            "YYYY-MM-DDTHH:mm:SS",
            "YYYY-MM-DDTHH:mm:SS.S",
            "YYYY-MM-DDTHH:mm:SS.SS",
            "YYYY-MM-DDTHH:mm:SS.SSS",
            "YYYY-MM-DDTHH:mm:SS.SSSS",
            "YYYY-MM-DDTHH:mm:SS.SSSSS",
            "YYYY-MM-DDTHH:mm:SS.SSSSSS",
            "YYYY-MM-DDTHH:mm:SS.SSSSSSS",
            "YYYY-MM-DDTHH:mm:SS.SSSSSSSZ"
        ];
    }

    getUserTimeZone(date: Date): string {
        const fmt = new Intl.DateTimeFormat('en',
            {
                timeZoneName: 'short'
            });
        if (date === null || date === undefined)
            date = new Date();
        return nullThrow(fmt.formatToParts(date).find((x) => x.type === "timeZoneName")).value;
    }

    getTenantFormattedDate(inputDate: string): string {
        if (this._isValidDate(inputDate)) {
            return this.utcToLocal(inputDate);
        }
        return "";
    }

    utcToLocal(date: string): string {
        const momentDate = this.moment.utc(date);
        return `${momentDate.local().format(this._outputFormat)}` + ` ${this.getUserTimeZone(momentDate.toDate())}`;
    }

    formatTimeStringAs24HourTimeString(time: string): string {
        // Converts "HH:mm:ss", "hh:mm tt" or "h:mm tt" to "HH:mm"
        //  where HH is the hour in 24 hour format (00 - 23), hh is the hour in 12 hour format (01 - 12),
        //  and tt is the AM or PM designator.
        const halfOfADay: number = 12; //hours
        if (/^\d{2}:\d{2}:\d{2}$/.exec(time)) {
            return time.slice(0, 5);
        } else if (/^\d{1,2}:\d{2} (AM|PM)$/i.exec(time)) {
            const amOrPm = time.slice(-2);
            const timeParts = time.split(" ")[0].split(":");
            const hourSegment = timeParts[0];
            const minuteSegment = timeParts[1];
            const hourSegmentIn24HrFormat = Number(hourSegment) === 12 ? 0 : Number(hourSegment)
            if (amOrPm === "PM") {
                return `${hourSegmentIn24HrFormat + halfOfADay}:${minuteSegment}`;
            }
            else
                return `${hourSegmentIn24HrFormat.toString().length === 1 ? "0" + hourSegmentIn24HrFormat : hourSegmentIn24HrFormat}:${minuteSegment}`;
        } else
            return "";
    }

    format24HourTimeStringAs12HourTimeString(time: string): string {
        // Converts "HH:mm:ss", "HH:mm" or "hh:mm" to "hh:mm tt"
        //  where HH is the hour in 24 hour format (00 - 23), hh is the hour in 12 hour format (01 - 12),
        //  and tt is the AM or PM designator.
        const halfOfADay: number = 12; //hours
        const midnightIn12HourFormat: number = 12;
        const midnightIn24HourFormat: number = 0;
        if (/^\d{2}:\d{2}(:\d{2})?$/.exec(time)) {
            const timeParts = time.split(":");
            const hourSegment = Number(timeParts[0]);
            const minuteSegment = timeParts[1];
            if (hourSegment >= halfOfADay) {
                const twelveHourFormatedHourSegment = hourSegment === halfOfADay ? hourSegment : hourSegment - halfOfADay;
                return `${twelveHourFormatedHourSegment.toString().length === 1 ? "0" + twelveHourFormatedHourSegment : twelveHourFormatedHourSegment}:${minuteSegment} PM`;
            }
            else if (hourSegment === midnightIn24HourFormat)
                return `${midnightIn12HourFormat}:${minuteSegment} AM`;
            else
                return `${hourSegment.toString().length === 1 ? "0" + hourSegment : hourSegment}:${minuteSegment} AM`;
        } else
            return "";
    }

    _isValidDate(dateString: string) {
        return this.moment(dateString, this._inputFormat, true).isValid();
    }
}