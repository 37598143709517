// Generated By NTypeWriter Visual Studio Extension and TypescriptTemplate.nt
// Tag types with [ExportToTypescript] attribute to generate these files, then build the solution

export enum FeeCategory {
  SuretyFee = 0,
  Ebca = 1,
  Custom1 = 2,
  Custom2 = 3,
  Custom3 = 4,
  Custom4 = 5,
  Custom5 = 6,
  Custom6 = 7,
  Custom7 = 8,
  Custom8 = 9,
  Custom9 = 10,
  Custom10 = 11
}