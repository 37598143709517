import { ICellEditorComp, ICellEditorParams } from "ag-grid-enterprise";
import { DateTimeFormatter } from "../Shared/DateTimeFormatter";

export class TimeStringCellEditor implements ICellEditorComp {
    private dateTimeFormatter!: DateTimeFormatter;
    private gui!: HTMLInputElement;
    private params!: ICellEditorParams;

    init(params: ICellEditorParams) {
        this.dateTimeFormatter = new DateTimeFormatter();
        this.gui = document.createElement("input");
        this.gui.classList.add('time-string-editor');
        this.gui.type = "time"
        this.params = params;
        const startValue = params.value;

        if (startValue !== null && startValue !== undefined) 
            this.gui.value = this.dateTimeFormatter.formatTimeStringAs24HourTimeString(startValue);
    }

    getGui(): HTMLElement {
        return this.gui;
    }

    getValue() {
        return this.dateTimeFormatter.format24HourTimeStringAs12HourTimeString(this.gui.value);
    }

    afterGuiAttached() {
        this.gui.focus();
    }
}