import bootstrap from './../../scss/bootstrap-custom.scss';
import bootstrapTable from 'bootstrap-table/dist/bootstrap-table.css';
import 'bootstrap-table/dist/bootstrap-table';
import { initializeHtmlElement } from './../HTMLElementExtensions';
import { getArrestInfoStatus, updateArrestInfo, getSpecialConditionsInfo, getAuthorizedByInfo, updateSpecialConditionsInfo, updateAuthorizationInfo } from './../EditDialogExtensions';
import { createColumn, dollarFieldFormatter, fieldFormatter } from './../BootstrapTableExtensions';
import template from './ReviewQueueArrestAndCourt.html';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import './../InmateInformation';
import './../EditArrestInfoDialog';
import './../EditSpecialConditionsDialog';
import './../ActionButton';
import { isAttributeTrue } from "./../JavaScriptFunctions";

class ReviewQueueArrestAndCourt extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable, fontawesome], ['container']);

        this._courtOptional = null;
        this._courtRequired = null;
        this._hasAgencyCaseNumber = null;
        this._bondApplicationId = null;
        this._courts = null;
        this._totalBondUiDelimiter = null;
        this._someCourtDetailHasExtraditionInfoRequiredFlag = null;
        this._isReviewAuthorizationRequired = null;
        this._courtInformationReadOnly = null;
        this._pageTools = new PageTools();
        this._title = this.shadowRoot.getElementById('title');
        this._inmateInformation = this.shadowRoot.getElementById('inmate-information');
        this._table = this.shadowRoot.getElementById('table');
        this._editButton = this.shadowRoot.getElementById('edit-arrest-info-button');
        this._specialConditionsButton = this.shadowRoot.getElementById('edit-special-conditions-button');
        this._authorizationButton = this.shadowRoot.getElementById('edit-review-authorization-button');
        this._returnButton = this.shadowRoot.getElementById('return-button');
        this._cancelButton = this.shadowRoot.getElementById('cancel-button');
        this._continueButton = this.shadowRoot.getElementById('continue-button');
        this._editArrestInfoDialog = this.shadowRoot.getElementById('edit-arrest-info-dialog');
        this._editSpecialConditionsDialog = this.shadowRoot.getElementById('edit-special-conditions-dialog');
        this._editReviewAuthorizationDialog = this.shadowRoot.getElementById('edit-review-authorization-dialog');

        this._openEditArrestInfoDialog = this._openEditArrestInfoDialog.bind(this);
        this._openSpecialConditionsDialog = this._openSpecialConditionsDialog.bind(this);
        this._openReviewAuthorizationDialog = this._openReviewAuthorizationDialog.bind(this);
        this._enableEditButtons = this._enableEditButtons.bind(this);
        this._disableEditButtons = this._disableEditButtons.bind(this);
        this._checkDisableEditButtons = this._checkDisableEditButtons.bind(this);
        this._returnButtonOnClick = this._returnButtonOnClick.bind(this);
        this._cancelButtonOnClick = this._cancelButtonOnClick.bind(this);
        this._continueButtonOnClick = this._continueButtonOnClick.bind(this);
        this._saveCourtButtonOnClick = this._saveCourtButtonOnClick.bind(this);
        this._saveSpecialConditionsOnClick = this._saveSpecialConditionsOnClick.bind(this);
        this._saveAuthorizationReviewsOnClick = this._saveAuthorizationReviewsOnClick.bind(this);
        this._getVisibilityStates = this._getVisibilityStates.bind(this);
    }

    set courtOptional(value) {
        this._courtOptional = value;
        this._editArrestInfoDialog.hasCourtRequired = !value;
    }

    set courtRequired(value) {
        this._courtRequired = value;
        this._editArrestInfoDialog.hasCourtRequired = value;
    }

    set courtHasAgencyCaseNumber(value) {
        this._hasAgencyCaseNumber = value;
        this._editArrestInfoDialog.hasAgencyCaseNumber = value;
    }

    set courts(value) {
        this._courts = value;
        this._editArrestInfoDialog.courts = value;
    }
    set totalBondUiDelimiter(value) {
        this._editArrestInfoDialog.totalBondUiDelimiter = value;
    }

    get _reviewAuthorizationRrequiredTempFeatureFlag() {
        return isAttributeTrue(this, 'data-review-authorization-required-temp');
    }

    set hasCauseNumberRequiredFeatureFlag(value) {
        this._editArrestInfoDialog.hasCauseNumberRequiredFeatureFlag = value;
    }

    set hasWarrantNumberRequiredFeatureFlag(value) {
        this._editArrestInfoDialog.hasWarrantNumberRequiredFeatureFlag = value;
    }

    set hasTotalBondsFeatureFlag(value) {
        this._editArrestInfoDialog.hasTotalBondsFeatureFlag = value;
    }

    set courtInformationReadOnly(value) {
        this._courtInformationReadOnly = value;
        this._editArrestInfoDialog.courtInformationReadOnly = value;
    }

    set model(value) {
        this._updateHtml(value);
        this._someCourtDetailHasExtraditionInfoRequiredFlag =
            value.CourtDetails.some(x => x.ExtraditionInformationRequired);

        const combinedModel = value.BondDetails.map(bondDetails =>
            ({
                BondApplicationDetailID: bondDetails.BondApplicationDetailID,
                BondDetails: bondDetails,
                CourtDetails: value.CourtDetails.find(x => x.BondApplicationDetailID === bondDetails.BondApplicationDetailID)
            })
        );
        this._loadTableData(combinedModel);
    }

    get _hasCancelPermission() {
        return isAttributeTrue(this, 'hasCancelPermission');
    }

    get _hasSpecialConditions() {
        return isAttributeTrue(this, 'has-special-conditions');
    }

    get _hasVerboseSpecialConditions() {
        return isAttributeTrue(this, 'has-verbose-special-conditions');
    }

    get _userIsCounty() {
        return isAttributeTrue(this, 'user-is-county');
    }

    connectedCallback() {
        this._editButton.addEventListener('click', this._openEditArrestInfoDialog);
        this._cancelButton.toggleAttribute('hidden', !this._hasCancelPermission);
        this._specialConditionsButton.addEventListener('click', this._openSpecialConditionsDialog);
        this._authorizationButton.addEventListener('click', this._openReviewAuthorizationDialog);
        this._returnButton.addEventListener('click', this._returnButtonOnClick);
        this._cancelButton.addEventListener('click', this._cancelButtonOnClick);
        this._continueButton.addEventListener('click', this._continueButtonOnClick);
        this._editArrestInfoDialog.addEventListener('save', this._saveCourtButtonOnClick);
        this._editSpecialConditionsDialog.addEventListener('save', this._saveSpecialConditionsOnClick);
        this._editArrestInfoDialog.hideCauseAndWarrantInformation = false;
        this._editReviewAuthorizationDialog.addEventListener('save', this._saveAuthorizationReviewsOnClick);
        this._inmateInformation.userIsCounty = this._userIsCounty;
    }

    disconnectedCallback() {
        this._editButton.removeEventListener('click', this._openEditArrestInfoDialog);
        this._specialConditionsButton.removeEventListener('click', this._openSpecialConditionsDialog);
        this._authorizationButton.removeEventListener('click', this._openReviewAuthorizationDialog);
        this._returnButton.removeEventListener('click', this._returnButtonOnClick);
        this._cancelButton.removeEventListener('click', this._cancelButtonOnClick);
        this._continueButton.removeEventListener('click', this._continueButtonOnClick);
        this._editArrestInfoDialog.removeEventListener('save', this._saveCourtButtonOnClick);
        this._editSpecialConditionsDialog.removeEventListener('save', this._saveSpecialConditionsOnClick);
        this._editReviewAuthorizationDialog.removeEventListener('save', this._saveAuthorizationReviewsOnClick);
    }

    _updateHtml(model) {
        this._bondApplicationId = model.Bond.BondApplicationID;
        this._inmateInformation.loadInmateInformationByBondApplicationId(model.Bond.BondApplicationID);

        if (!this._courtOptional && !this._courtRequired) {
            this._editButton.textContent = 'Edit Arrest Info';
            this._title.textContent = 'Arrest Information';
            this._editArrestInfoDialog.hideCourtInformation = true;
        } else {
            this._editButton.textContent = 'Edit Arrest / Court Information';
            this._title.textContent = 'Arrest / Court Information';
            this._editArrestInfoDialog.hideCourtInformation = false;
        }
        this._isReviewAuthorizationRequired = this._reviewAuthorizationRrequiredTempFeatureFlag && model.Bond.IsReviewAuthorizationRequired;

        this._specialConditionsButton.toggleAttribute('hidden', !this._hasSpecialConditions && !this._hasVerboseSpecialConditions);
        this._authorizationButton.toggleAttribute('hidden', !this._isReviewAuthorizationRequired);
    }

    _loadTableData(model) {
        const isReviewAuthorizationRequired = !this._authorizationButton.hasAttribute('hidden');
        const table = $(this._table);
        const columns = [
            {
                 checkbox: true
            }];
        columns.push(createColumn('Degree', `BondDetails.OffenseDegree`));
        columns.push(createColumn('Offense', `BondDetails.OffenseDesc`));
        columns.push(createColumn('Bond Amount', `BondDetails.BondAmt`, undefined, dollarFieldFormatter));
        columns.push(createColumn((this._courtOptional || this._courtRequired) ? 'Arrest / Court Information' : 'Arrest Information', 'ArrestInfo', false, fieldFormatter));
        if (this._hasSpecialConditions || this._hasVerboseSpecialConditions) {
            columns.push(createColumn('Special Conditions', 'SpecialConditionsInfo', false, fieldFormatter));
        }
        if (isReviewAuthorizationRequired) {
            columns.push(createColumn('Authorization', 'AuthorizationInfo', false, fieldFormatter));
        }
        

        model.forEach(item => {
            const arrestInfoStatusObject = {
                courtNameField: item.CourtDetails.CourtName,
                agencyCaseNumberField: item.CourtDetails.AgencyCaseNumber,
                causeWarrantField: item.CourtDetails.CauseNum || item.CourtDetails.WarrantNum,
                courtRequired: this._courtRequired,
                courtOptional: this._courtOptional,
                totalBondUiDelimiter: this._totalBondUiDelimiter,
                agencyCaseNumberRequired: this._hasAgencyCaseNumber,
                extraditionInfoRequired:
                    this._someCourtDetailHasExtraditionInfoRequiredFlag &&
                    !this._editArrestInfoDialog.hideCauseAndWarrantInformation,
                extraditionStateField: item.CourtDetails.ExtraditionState
            }
            const visibilityStateObject = this._getVisibilityStates();
            item.ArrestInfo = getArrestInfoStatus(arrestInfoStatusObject, visibilityStateObject);
            item.SpecialConditionsInfo = getSpecialConditionsInfo(item.BondDetails.SpecialConditions, this._hasSpecialConditions, this._hasVerboseSpecialConditions);
            item.AuthorizationInfo = getAuthorizedByInfo(item.BondDetails.ReviewAuthorizedBy);
        });

        table.bootstrapTable({
            columns: columns,
            data: model,
            classes: 'table table-sm table-striped table-bordered table-hover',
            clickToSelect: true,
            detailView: true,
            detailFormatter: this._detailFormatter.bind(this),
            uniqueId: 'BondApplicationDetailID',
            onCheck: this._enableEditButtons,
            onCheckAll: this._enableEditButtons,
            onUncheck: this._checkDisableEditButtons,
            onUncheckAll: this._disableEditButtons,
        });
        table.bootstrapTable('checkAll');
        table.bootstrapTable('expandAllRows');
        this._updateButtonStates();
    }
    
    _updateButtonStates() {
        const $table = $(this._table);
        const data = $table.bootstrapTable('getData', {
            includeHiddenRows: true,
            unfiltered: true,
            formatted: false,
        });

        let disableContinueButton = false;
        disableContinueButton = updateInfoButtonState(this._editButton, 'ArrestInfo') || disableContinueButton;
        if (this._hasSpecialConditions || this._verboseSpecialConditionsFeatureFlag) {
            disableContinueButton = updateInfoButtonState(this._specialConditionsButton, 'SpecialConditionsInfo') || disableContinueButton;
        }
        if (!this._authorizationButton.hasAttribute('hidden')) {
            disableContinueButton = updateInfoButtonState(this._authorizationButton, 'AuthorizationInfo') || disableContinueButton;
        }

        this._continueButton.toggleAttribute('disabled', disableContinueButton);

        function updateInfoButtonState(button, propertyNameToCheck) {
            const isFieldRequired = data.some(item => item[propertyNameToCheck] === 'Required');
            button.classList.toggle('btn-primary', isFieldRequired);
            button.classList.toggle('btn-outline-primary', !isFieldRequired);
            return isFieldRequired;
        }
    }

    _getVisibilityStates() {
        return {
            isWarrantInfoDisplayed: !this._editArrestInfoDialog.hideCauseAndWarrantInformation,
            isCourtInfoDisplayed: !this._editArrestInfoDialog.hideCourtInformation,
            isAgencyCaseNumberDisplayed: !this._editArrestInfoDialog.hideCauseAndWarrantInformation && !this._editArrestInfoDialog.hideAgencyCaseNumberInformation,
            isExtraditionStateDisplayed: !this._editArrestInfoDialog.hideCauseAndWarrantInformation && this._someCourtDetailHasExtraditionInfoRequiredFlag
        };
    }

    _getCourtDate(rowCourtTime, rowCourtDate) {
        if ((!rowCourtTime && !rowCourtDate) || (!Date.parse(rowCourtTime) && !Date.parse(rowCourtDate)) )
            return '';

        if (rowCourtTime) {
            return window.DateFormatter.convertToDateCourtFormat(rowCourtTime);
        }
        return window.DateFormatter.convertToDateCourtDate(rowCourtDate);
    }

    _getCourtTime(rowCourtTime) {
        if (!rowCourtTime) {
            return '';
        }
        return window.DateFormatter.convertToTimeAmPm(rowCourtTime);
    }

    _detailFormatter(index, row, element) {
        const data = this._getDetailFormatterData(
            row,
            this._courtOptional || this._courtRequired,
            this._hasAgencyCaseNumber,
            this._someCourtDetailHasExtraditionInfoRequiredFlag &&
            !this._editArrestInfoDialog.hideCauseAndWarrantInformation,
            this._isReviewAuthorizationRequired
        );
        const html = [];
        Object.values(data).forEach(value => {
            html.push('<p><b>' + value.displayName + ':</b> ' + value.fieldValue + '</p>');
        });
        return html.join('');
    }

    _getDetailFormatterData(row, hasCourtInformation, hasAgencyCaseNumber, hasExtraditionInfo, hasAuthorizedBy) {
        const dataObj = {};

        this._getDetailFormatterAgencyCaseNum(dataObj, row, hasAgencyCaseNumber);

        dataObj.CauseNum = { displayName: 'Cause/Docket Number', fieldValue: row.CourtDetails.CauseNum || '' };
        dataObj.WarrantNum = { displayName: 'Warrant Number', fieldValue: row.CourtDetails.WarrantNum || '' };
        this._getDetailFormatterExtraditionInfo(dataObj, row, hasExtraditionInfo);
        this._getDetailFormatterCourts(dataObj, row, hasCourtInformation);
        this._getDetailFormatterSpecialConditions(dataObj, row);
        this._getDetailFormatterVerboseSpecialConditions(dataObj, row);
        this._getDetailFormatterAuthorizedBy(dataObj, row, hasAuthorizedBy);

        return dataObj;
    }
    
    _getDetailFormatterExtraditionInfo(dataObj, row, hasExtraditionInfo) {
        if (hasExtraditionInfo) {
            dataObj.ExtraditionInfo = {
                displayName: 'Extradition State',
                fieldValue: row.CourtDetails.ExtraditionState ?? ''
            }
        }
    }

    _getDetailFormatterAgencyCaseNum(dataObj, row, hasAgencyCaseNumber) {
        if (hasAgencyCaseNumber) {
            dataObj.AgencyCaseNum = {
                displayName: 'Agency Case Number',
                fieldValue: row.CourtDetails.AgencyCaseNumber || ''
            };
        }
    }

    _getDetailFormatterCourts(dataObj, row, hasCourtInformation) {
        if (hasCourtInformation) {
            const selectedCourt = this._courts.find(c => c.CourtID === parseInt(row.CourtDetails.Court));
            const courtDate = this._getCourtDate(row.CourtDetails.CourtTime, row.CourtDetails.CourtDate);
            const courtTime = this._getCourtTime(row.CourtDetails.CourtTime);
            dataObj.Court = {
                displayName: 'Court Name',
                fieldValue: selectedCourt ? selectedCourt.CourtName : row.CourtDetails.CourtName || ''
            };
            dataObj.CourtDateTime = {
                displayName: 'Court Date',
                fieldValue: `${courtDate} ${courtTime}`.trim()
            };
            dataObj.AlternateCourt = {
                displayName: 'Alternate Court Appearance',
                fieldValue: row.CourtDetails.AlternateCourt || ''
            };
        }
    }

    _getDetailFormatterSpecialConditions(dataObj, row) {
        if (this._hasSpecialConditions) {
            let specialConditionsValue;
            if (row.BondDetails.SpecialConditions == null) {
                specialConditionsValue = '';
            } else {
                specialConditionsValue = row.BondDetails.SpecialConditions ? 'Yes' : 'No';
            }
            dataObj.specialConditions = {
                displayName: 'Special Conditions',
                fieldValue: specialConditionsValue
            }
        }
    }

    _getDetailFormatterVerboseSpecialConditions(dataObj, row) {
        if (this._hasVerboseSpecialConditions && row.BondDetails.SpecialConditions === true) {
            let conditions;
            if (row.Conditions){
                conditions = row.Conditions;
            } else {
                conditions = row.BondDetails.VerboseSpecialConditions;
            }

        dataObj.conditions = {
                displayName: 'Conditions',
                fieldValue: conditions
            }
        }
    }

    _getDetailFormatterAuthorizedBy(dataObj, row, hasAuthorizedBy) {
        if (hasAuthorizedBy) {
            let authorizedByValue;
            if (row.BondDetails.ReviewAuthorizedBy == null) {
                authorizedByValue = '';
            } else {
                authorizedByValue = row.BondDetails.ReviewAuthorizedBy;
            }
            dataObj.reviewAuthorizedBy = {
                displayName: 'Authorized By',
                fieldValue: authorizedByValue
            }
        }
    }

    _saveCourtButtonOnClick(event) {
        const visibilityStateObject = this._getVisibilityStates();
        const courtOptions = {
            required: this._courtRequired,
            optional: this._courtOptional,
            readOnly: this._courtInformationReadOnly
        };
        updateArrestInfo(
            this._table,
            event,
            courtOptions,
            this._hasAgencyCaseNumber,
            null,
            visibilityStateObject
        );
        $(this._table).bootstrapTable('expandAllRows');
        this._updateButtonStates();
    }

    _saveSpecialConditionsOnClick(event) {
        updateSpecialConditionsInfo(this._table, "BondDetails", event, this._hasSpecialConditions, this._hasVerboseSpecialConditions);
        $(this._table).bootstrapTable('expandAllRows');
        this._updateButtonStates();
    }

    _saveAuthorizationReviewsOnClick(event) {
        updateAuthorizationInfo(this._table, "BondDetails", event);
        $(this._table).bootstrapTable('expandAllRows');
        this._updateButtonStates();
    }

    _returnButtonOnClick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._pageTools.redirectToUrl('/ReviewQueue');
    }

    _continueButtonOnClick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._pageTools.redirectToUrl(`/ReviewQueue/CompleteReview/${this._bondApplicationId}`);
    }

    _cancelButtonOnClick() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._pageTools.redirectToUrl(`/Cancel/FinishCancel/${this._bondApplicationId}`);
    }

    _openEditArrestInfoDialog() {
        const selectedCharges = $(this._table).bootstrapTable('getSelections');
        this._editArrestInfoDialog.openModal(selectedCharges.map(x => x.CourtDetails));
    }

    _openSpecialConditionsDialog() {
        const selectedCharges = $(this._table).bootstrapTable('getSelections');
        this._editSpecialConditionsDialog.hasVerboseSpecialConditions = this._hasVerboseSpecialConditions;
        this._editSpecialConditionsDialog.specialConditions = this._hasSpecialConditions;
        this._editSpecialConditionsDialog.openModal(selectedCharges.map(x => x.BondDetails), 'ReviewQueue');
    }

    _openReviewAuthorizationDialog() {
        const selectedCharges = $(this._table).bootstrapTable('getSelections');
        this._editReviewAuthorizationDialog.openModal(selectedCharges.map(x => x.BondDetails), 'ReviewQueue');
    }

    _enableEditButtons() {
        this.shadowRoot.querySelectorAll('#edit-button-container button').forEach(element => {
            element.toggleAttribute('disabled', false);
        });
    }

    _disableEditButtons() {
        this.shadowRoot.querySelectorAll('#edit-button-container button').forEach(element => {
            element.toggleAttribute('disabled', true);
        });
    }

    _checkDisableEditButtons() {
        const $table = $(this._table);
        if ($table.bootstrapTable('getSelections').length === 0) {
            this._disableEditButtons();
        }
    }
}
customElements.define('review-queue-arrest-and-court', ReviewQueueArrestAndCourt);