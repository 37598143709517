import { DataTypeDefinition, ValueFormatterLiteParams } from "ag-grid-enterprise";
import { DateTimeFormatter } from "../Shared/DateTimeFormatter";

export function getDefaultTimeStringDataDefinition<TData>(): DataTypeDefinition<TData>
{
    return {
        baseDataType: "text",
        extendsDataType: "text",
        valueFormatter: (params: ValueFormatterLiteParams<TData, string>) =>
        {
            const value = params.value;
            if (value == null)
                return "";
            else if (/^\d{1,2}:\d{2}:\d{2}$/.exec(value))
                return new DateTimeFormatter().format24HourTimeStringAs12HourTimeString(value);
            else
                return value;
        },
        dataTypeMatcher: (value: any) =>
            typeof value === "string" && !!/^\d{1,2}:\d{2}(( (AM|PM)?)|(:\d{2})?)$/i.exec(value)
    }
}