import bootstrap from "./../../../scss/bootstrap-custom.scss";
import bootstrapTable from "bootstrap-table/dist/bootstrap-table.css";
import "bootstrap-table/dist/bootstrap-table";
import { initializeHtmlElement } from "./../../HTMLElementExtensions";
import { createColumn, dollarFieldFormatter, dateTimeFormatterWithTz } from "./../../BootstrapTableExtensions";
import template from "./CashTransactionIndex.html";
import allPaidPaymentStatus from "../../Enumerations/AllPaidPaymentStatus";
import fontawesome from "@fortawesome/fontawesome-free/css/all.css";
import "./../../ActionButton";
import "./../../Cancel/CancelReasonModal";
import "../../AlertMessage";
import "./../../InmateInformation";

class CashTransactionIndex extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable, fontawesome], ["container"]);

        this._pageTools = new PageTools();
        this._searchInput = this.shadowRoot.getElementById("search-input");
        this._searchButton = this.shadowRoot.getElementById("search-button");
        this._inmateInformation = this.shadowRoot.getElementById("inmate-information");
        this._table = this.shadowRoot.getElementById("table");
        this._alertMessage = this.shadowRoot.getElementById("alert-message");

        this._tooltip = this.shadowRoot.getElementById("title-tooltip");
        this._transactionId = this.shadowRoot.getElementById("transaction-id");
        this._transactionPayor = this.shadowRoot.getElementById("transaction-payor");
        this._transactionTotal = this.shadowRoot.getElementById("transaction-total");
        this._transactionTimestamp = this.shadowRoot.getElementById("transaction-timestamp");
        this._transactionStatus = this.shadowRoot.getElementById("transaction-status");
        this._transactionCanceledOn = this.shadowRoot.getElementById("transaction-canceled-on");
        this._transactionCanceledBy = this.shadowRoot.getElementById("transaction-canceled-by");
        this._transactionCanceledReason = this.shadowRoot.getElementById("transaction-canceled-reason");
        this._transactionCanceledOnContainer = this.shadowRoot.getElementById("transaction-canceled-on-container");
        this._transactionCanceledByContainer = this.shadowRoot.getElementById("transaction-canceled-by-container");
        this._transactionCanceledReasonContainer = this.shadowRoot.getElementById("transaction-canceled-reason-container");
        this._cancelTransactionButton = this.shadowRoot.getElementById("cancel-button");
        this._cancelTransactionReasonModal = this.shadowRoot.getElementById("cancel-reason-modal");
        this._cancelledTransactionWarningMessage = this.shadowRoot.getElementById("canceled-transaction-warning-message");
        this._searchResults = this.shadowRoot.getElementById("search-results");

        this._searchButtonOnclick = this._searchButtonOnclick.bind(this);
        this._searchRequestCallback = this._searchRequestCallback.bind(this);
        this._inputOnKeyUp = this._inputOnKeyUp.bind(this);
        this._cancelTransactionOnClick = this._cancelTransactionOnClick.bind(this);
    }

    get _userIsCounty() {
        return this.hasAttribute("user-is-county") &&
            this.getAttribute("user-is-county").toLowerCase() === "true";
    }

    connectedCallback() { 
        this._loadTableData([]);
        this._directSearch();
        $(this._tooltip).tooltip();
        this._searchInput.addEventListener("keyup", this._inputOnKeyUp);
        this._searchButton.addEventListener("click", this._searchButtonOnclick);
        this._cancelTransactionButton.addEventListener("click", this._cancelTransactionOnClick);
        this._inmateInformation.userIsCounty = this._userIsCounty;
    }

    disconnectedCallback() {
        this._searchInput.removeEventListener("keyup", this._inputOnKeyUp);
        this._searchButton.removeEventListener("click", this._searchButtonOnclick);
        this._cancelTransactionButton.removeEventListener("click", this._cancelTransactionOnClick);
    }

    _inputOnKeyUp(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            this._searchButton.click();
        }
    }

    _loadTableData(model) {
        const table = $(this._table);
        const columns = [];
        columns.push(createColumn("Booking Number", "bookingNumber"));
        columns.push(createColumn("Location", "location"));
        columns.push(createColumn("Bond Type", "bondType"));
        columns.push(createColumn("Total Bond Amount", "totalBondAmount", undefined, dollarFieldFormatter));
        columns.push(createColumn("Total Bond Fees", "totalCountyBondFees", undefined, dollarFieldFormatter));
        columns.push(createColumn("Status", "status"));
        columns.push(createColumn("Timestamp", "timestamp", undefined, dateTimeFormatterWithTz));

        table.bootstrapTable({
            columns: columns,
            data: model,
            classes: "table table-sm table-striped table-bordered mt-2",
            detailView: true,
            detailFormatter: this._detailFormatter,
            formatNoMatches: () => "There are no bond applications associated with this payment."
        });
    }

    _detailFormatter(index, row, element) {
        const columns = [];
        columns.push(createColumn("Arrest Date", "arrestDate", undefined, dateTimeFormatterWithTz));
        columns.push(createColumn("Offense Degree", "offenseDegree"));
        columns.push(createColumn("Offense Description", "offenseDescription"));
        columns.push(createColumn("Bond Amount", "bondAmount", undefined, dollarFieldFormatter));
        columns.push(createColumn("Transaction Amount", "transactionAmount", undefined, dollarFieldFormatter));

        const $table = element.html("<table></table>").find("table");
        $table.bootstrapTable({
            columns: columns,
            classes: "table table-sm table-striped table-bordered",
            data: row.offenses
        });
    }

    _directSearch() {
        const urlParams = new URLSearchParams(this._pageTools.getLocation().search);
        const transactionId = urlParams.get("transactionId");
        if (transactionId) {
            this._searchInput.value = transactionId;
            this._searchRequest(transactionId);
        }
    }

    _searchButtonOnclick() {
        this._alertMessage.clearMessage();
        const transactionId = this._searchInput.value;
        if (transactionId) {
            this._searchRequest(transactionId);
        }
    }

    _searchRequest(transactionId) {
        this._searchButton.disabled = true;
        this._searchButton.setSpinner(true);
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("POST",
            "/Admin/CashTransaction/Search",
            { transactionId: transactionId },
            this._searchRequestCallback
        );
    }

    _searchRequestCallback(response, isSuccess) {
        this._searchButton.disabled = false;
        if (!isSuccess) {
            this._showErrorMessage("There was an error while retrieving the search results. Please try again and if the problem persists <contact-link>contact eBONDS™ support</contact-link>.");
            return;
        }
        const model = this._pageTools.tryParseJson(response);
        if (model) {
            this._transactionId.value = model.transaction.transactionId;
            this._transactionPayor.value = model.transaction.payor;
            this._transactionTotal.value = this._pageTools.formatNumberToDollarAmount(model.transaction.totalTransactionAmount);
            this._transactionTimestamp.value = window.DateFormatter.utcToLocal(model.transaction.timestamp);
            this._transactionStatus.value = Object.values(allPaidPaymentStatus)
                .find(x => x.value === model.transaction.status).string;
            this._transactionCanceledBy.value = model.transaction.user;
            this._transactionCanceledReason.value = model.transaction.reason;
            this._showCanceledFieldsByTransactionStatus(model.transaction.status);
            $(this._table).bootstrapTable("load", model.applications);
            this._inmateInformation.loadInmateInformationBySoNumber(model.transaction.soNumber);
            this._alertMessage.clearMessage();
            this._searchResults.toggleAttribute("hidden", false);
            if (model.transaction.status === allPaidPaymentStatus.cancelled.value) {
                this._transactionCanceledOn.value = window.DateFormatter.utcToLocal(model.transaction.canceledOn);
            }
            if (model.transaction.status !== allPaidPaymentStatus.cancelled.value) {
                this._toggleCancelTransactionButton();
            }
            this._setCancelWarningMessage(model.transaction.status);
            if (model.applications.some((x) => x.status === "Cancelled")) {
                const canceledApps = model.applications.map((x) => (
                    {
                        cancelDate: new Date(x.timestamp),
                        cancelReason: x.reason
                    }
                ));
                canceledApps.sort((a, b) => b.cancelDate - a.cancelDate); // descending order
                this._cancelTransactionReasonModal.cancelReason = canceledApps[0].cancelReason;
            }
            
            return;
        }
        this._showErrorMessage("The search returned no results. Enter another value and try again.");
    }

    _showCanceledFieldsByTransactionStatus(status) {
        const isStatusNotCanceled = status !== allPaidPaymentStatus.cancelled.value;
        this._transactionCanceledOnContainer.toggleAttribute("hidden", isStatusNotCanceled);
        this._transactionCanceledByContainer.toggleAttribute("hidden", isStatusNotCanceled);
        this._transactionCanceledReasonContainer.toggleAttribute("hidden", isStatusNotCanceled);
    }

    _cancelTransactionOnClick() {
        const data = {
            title: "Mark as Canceled Transaction Reason",
            endPoint: "/Admin/CashTransaction/CancelElectronicCashTransaction",
            redirectUrl: `/Admin/CashTransaction?transactionId=${encodeURIComponent(this._transactionId.value)}`,
            requestObject: { referenceNumber: this._transactionId.value },
            hideBondAgentWarning: true
        };
        this._cancelTransactionReasonModal.openModal(data);
    }

    _showErrorMessage(message) {
        this._searchResults.toggleAttribute("hidden", true);
        this._alertMessage.showErrorMessage(message, true);
    }

    _setCancelWarningMessage(status) {
        const shouldHide = status !== allPaidPaymentStatus.cancelled.value;
        this._cancelledTransactionWarningMessage.toggleAttribute("hidden", shouldHide);
    }

    _toggleCancelTransactionButton() {
        this._cancelTransactionButton.removeAttribute("hidden");
    }
}
customElements.define("cash-transaction-index", CashTransactionIndex);