import template from './ManageUserModal.html';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css';
import bootstrap from '../../scss/bootstrap-custom.scss';

class ManageUserModal extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, fontawesome]);

        this._pageTools = new PageTools();
        this._model = null;
        this._editorBondCompanies = null;
        this._editorIsCounty = null;
        this._companies = [];
        this._permissions = null;
        this._confirmationModal = this.shadowRoot.querySelector("confirmation-modal");
        this._contactCountyMessage = "Please contact your jail administrator to change this setting.";
        this._modalComponent = this.shadowRoot.querySelector("modal-component");
        this._accountStatusCheckbox = this.shadowRoot.getElementById("status-checkbox");
        this._statusMessage = this.shadowRoot.getElementById("status-message");
        this._companiesGroup = this.shadowRoot.getElementById("companies-group");
        this._companiesErrorLabel = this.shadowRoot.getElementById("companies-error-label");
        this._permissionsGroup = this.shadowRoot.getElementById("permissions-group");
        this._permissionsContainer = this.shadowRoot.getElementById('permissions-container');
        this._permissionsLoadingSpinner = this.shadowRoot.getElementById('permissions-loading-spinner');
        this._permissionsLabel = this.shadowRoot.getElementById("modal-content-label-permissions");
        this._permissionsErrorLabel = this.shadowRoot.getElementById("permissions-error-label");
        this._modalContentRole = this.shadowRoot.getElementById("modal-content-role");
        this._roleLabel = this.shadowRoot.getElementById("modal-content-label-role");
        this._companyLabel = this.shadowRoot.getElementById("modal-content-label-companies");
        this._accountLabel = this.shadowRoot.getElementById("modal-content-label-account");
        this._modalContentName = this.shadowRoot.getElementById("modal-content-name");
        this._modalContentEmail = this.shadowRoot.getElementById("modal-content-email");
        this._roleErrorLabel = this.shadowRoot.getElementById("role-error-label");
        this._modalOption1 = this.shadowRoot.getElementById("modal-content-role-1");
        this._modalOption2 = this.shadowRoot.getElementById("modal-content-role-2");
        this._modalOption3 = this.shadowRoot.getElementById("modal-content-role-3");
        this._modalOption4 = this.shadowRoot.getElementById("modal-content-role-4");
        this._modalContentEmployeeId = this.shadowRoot.getElementById("modal-content-employee-id");
        this._saveButton = this.shadowRoot.getElementById("user-action-button");
        this._closeButton = this.shadowRoot.getElementById("closeModal-button");
        this._companiesContainer = this.shadowRoot.getElementById('companies-container');
        this._userBondCompaniesConfiguration = this.shadowRoot.getElementById('user-bond-companies-configuration');
        this._warningMessage = this.shadowRoot.getElementById('warning-message');
        this._tooltipElements = this.shadowRoot.querySelectorAll('span[role="tooltip"]');

        this._roleChanged = this._roleChanged.bind(this);
        this._permissionItemOnClick = this._permissionItemOnClick.bind(this);
        this._statusMessageUpdate = this._statusMessageUpdate.bind(this);
        this._statusMessageDisabled = this._statusMessageDisabled.bind(this);
        this._saveUser = this._saveUser.bind(this);
        this._closeModal = this._closeModal.bind(this);
        this._showDisabledCompanyErrorSpan = this._showDisabledCompanyErrorSpan.bind(this);
        this._checkCompaniesSelectedCount = this._checkCompaniesSelectedCount.bind(this);
        this._showChangeUserRoleConfirmationModal = this._showChangeUserRoleConfirmationModal.bind(this);
    }

    static get observedAttributes() {
        return ["editor-is-county"];
    }

    attributeChangedCallback(name, oldModel, newModel) {
        if (oldModel === newModel)
            return;

        if (name === "editor-is-county")
            this.editorIsCounty = newModel === "true";
    }

    set model(value) {
        this._model = value;

        const tableData = this._companies.map(company => {
            const model = this._model.UserBondCompanyConfigurations.find(config => config.BondCompanyId === company.BondCompanyId);
            const enforceLimit = model ? model.EnforceLimit : false;
            const limit = model ? model.Limit : null;
            return ({
                BondCompanyId: company.BondCompanyId,
                BondCompanyName: company.CompanyName,
                EnforceLimitSelected: enforceLimit,
                Limit: limit,
                License: model?.License,
                CompanySelected: this._model.BondCompanyIds.includes(company.BondCompanyId)
            });
        });

        this._userBondCompaniesConfiguration.userBondCompanyIds = this._model.BondCompanyIds;
        this._userBondCompaniesConfiguration.editorIsCounty = this._editorIsCounty;
        this._userBondCompaniesConfiguration.model = tableData;
    }

    set editorBondCompanies(value) {
        this._editorBondCompanies = value;
        this._userBondCompaniesConfiguration.editorBondCompanies = value;
    }

    set editorIsCounty(value) {
        this._editorIsCounty = value;
    }

    set expandRoleOptionsFeatureFlagTemp(value) {
        this._expandRoleOptionsFeatureFlagTemp = value;
    }

    set companies(value) {
        this._companies = value;
    }

    set permissions(value) {
        this._permissions = value;
    }

    get _userLevelPoaLicenseFeature() {
        const attr = "data-user-level-poa-license";
        return this.hasAttribute(attr) && this.getAttribute(attr) === "true";
    }

    connectedCallback() {
        this._accountStatusCheckbox.addEventListener("change", this._statusMessageUpdate);
        this._accountStatusCheckbox.addEventListener("click", this._statusMessageDisabled);
        this._modalContentRole.addEventListener('change', this._roleChanged);
        this._saveButton.addEventListener("click", this._showChangeUserRoleConfirmationModal);
        this._closeButton.addEventListener("click", this._closeModal);
        const dataEnforceBondAppAttr = 'data-enforce-bond-application-limits-temp';
        const dataEnforceBondAppValue = this.hasAttribute(dataEnforceBondAppAttr) && this.getAttribute(dataEnforceBondAppAttr).toLowerCase() === 'true';
        this._userBondCompaniesConfiguration.setAttribute('data-enforce-bond-application-limits-temp', dataEnforceBondAppValue);
        this._userBondCompaniesConfiguration.setAttribute("data-user-level-poa-license", this._userLevelPoaLicenseFeature);
        this._userBondCompaniesConfiguration.addEventListener("showDisabledCompanyErrorSpan", this._showDisabledCompanyErrorSpan);
        this._userBondCompaniesConfiguration.addEventListener("companiesSelectedCount", this._checkCompaniesSelectedCount);
        this._confirmationModal.addEventListener('ok-confirmation', this._saveUser);
        this._tooltipElements.forEach(element => { $(element).tooltip(); });
    }

    disconnectedCallback() {
        this._accountStatusCheckbox.removeEventListener("change", this._statusMessageUpdate);
        this._accountStatusCheckbox.removeEventListener("click", this._statusMessageDisabled);
        this._modalContentRole.removeEventListener('change', this._roleChanged);
        this._saveButton.removeEventListener("click", this._showChangeUserRoleConfirmationModal);
        this._closeButton.removeEventListener("click", this._closeModal);
        this._userBondCompaniesConfiguration.removeEventListener("showDisabledCompanyErrorSpan", this._showDisabledCompanyErrorSpan);
        this._userBondCompaniesConfiguration.removeEventListener("companiesSelectedCount", this._checkCompaniesSelectedCount);
        this._confirmationModal.removeEventListener('ok-confirmation', this._saveUser);
    }

    openModal() {
        this._restModal();

        if (!this._editorIsCounty) {
            this._companyAdminSetup(this._model);
        } else if(this._expandRoleOptionsFeatureFlagTemp) {
            this._modalOption3.removeAttribute('hidden');
            this._modalOption4.removeAttribute('hidden');
        }

        this._modalContentName.value = this._model.DisplayName;
        this._modalContentEmail.value = this._model.Email;
        this._setRoles();
        this._modalContentEmployeeId.value = this._model.EmployeeId;

        this._updatePermissionList(this._model.Role);
        this._setInitialStatusState();
        this._companiesGroup.toggleAttribute('hidden', this._model.Role.includes("Jail"));
        this._modalComponent.openModal(`${this._model.DisplayName}`, false);
    }

    _updatePermissionList(selectedRole) {
        this._permissionsErrorLabel.textContent = '';
        const permissionList = this._permissions[selectedRole];
        this._permissionsLoadingSpinner.toggleAttribute('hidden', false);
        this._permissionsContainer.toggleAttribute('hidden', true);
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            'POST',
            '/Users/GetUserSpecificPermissions/',
            { userId: this._model.UserId },
            this._getUserSpecificPermissionsCallback.bind(this, permissionList)
        );

        this._permissionsGroup.toggleAttribute('hidden', !permissionList || permissionList.length === 0);
    }

    _getUserSpecificPermissionsCallback(permissionList, response, isSuccess) {
        const checkboxList = this._permissionsContainer;
        while (checkboxList.children.length > 0) {
            const child = checkboxList.lastElementChild;
            child.removeEventListener('click', this._permissionItemOnClick);
            checkboxList.removeChild(child);
        }

        const permissions = this._pageTools.tryParseJson(response);
        permissionList.forEach(x => {
            const permissionItem = this._generateListItem(x.Value, x.Text);
            if (permissions && Array.isArray(permissions)) {
                const checkBox = permissionItem.querySelector('input');
                checkBox.checked = permissions.includes(x.Value);
                permissionItem.classList.toggle('selected', checkBox.checked);
            }
            permissionItem.classList.add('permission-item');
            permissionItem.addEventListener("click", this._permissionItemOnClick);
            checkboxList.appendChild(permissionItem);
        });

        this._permissionsLoadingSpinner.toggleAttribute('hidden', true);
        checkboxList.toggleAttribute('hidden', false);
        if (!isSuccess)
            this._permissionsErrorLabel.textContent = 'Failed to load user\'s permissions';
    }

    _enableSaveButtonAndClearCompaniesErrorLabel() {
        this._saveButton.removeAttribute("disabled");
        this._companiesErrorLabel.textContent = "";
        this._companiesErrorLabel.toggleAttribute('hidden', true);
    }

    _checkCompaniesSelectedCount(event) {
        if (event.detail.selectedCompaniesCount > 0) {
            this._enableSaveButtonAndClearCompaniesErrorLabel();
            return;
        } 

        this._saveButton.disabled = true;
        this._companiesErrorLabel.toggleAttribute('hidden', false);
        this._companiesErrorLabel.textContent = 'You must have at least one company selected.';
    }

    _roleChanged(event) {
        const currentTarget = event.currentTarget;
        const selectedRole = currentTarget.value;

        if (this._expandRoleOptionsFeatureFlagTemp) {
            this._setRoleChangeWarning(selectedRole);

            if (this._editorIsCounty) {
                if (selectedRole.includes("Bond Company")) {
                    this._companiesGroup.toggleAttribute('hidden', false);
                    const tableData = this._userBondCompaniesConfiguration.getTableData();
                    const selectedCompaniesCount = Array.from(tableData.filter(x => x.CompanySelected).map(x => parseInt(x.BondCompanyId))).length;
                    if (this._model.Role.includes("Jail"))
                        this._checkCompaniesSelectedCount({ detail: { selectedCompaniesCount: selectedCompaniesCount } });
                } else {
                    this._companiesGroup.toggleAttribute('hidden', true);
                    this._enableSaveButtonAndClearCompaniesErrorLabel();
                }
            }
        }
        this._updatePermissionList(selectedRole);
    }

    _setRoleChangeWarning(newRole) {
        this._warningMessage.textContent = "";
        const oldRole = this._model.Role;
        if (newRole !== oldRole) {
            this._warningMessage.textContent = `WARNING! You are about to change the user's role from ${oldRole} to ${newRole}!`;
        }
    }

    _showChangeUserRoleConfirmationModal() {
        if (!this._userBondCompaniesConfiguration.reportValidity())
            return;

        const roleChanged = this._modalContentRole.value !== this._model.Role;
        if (!this._expandRoleOptionsFeatureFlagTemp || !roleChanged) {
            this._saveUser();
            return;
        }

        this._confirmationModal.open({
            message: `Are you sure you want to change the user's role from ${this._model.Role} to ${
                this._modalContentRole.value}?`,
            response: "response",
            actionButtonText: "Change Role"
        });
    }

    _closeModal() {
        this._warningMessage.textContent = "";
        this._modalComponent.closeModal();
    }

    _generateListItem(key, value) {
        const input = document.createElement('input');
        input.type = 'checkbox';
        input.value = key;

        const span = document.createElement('span');
        span.classList.add('ml-3');
        span.textContent = value;

        const listItem = document.createElement('div');
        listItem.classList.add('d-flex', 'p-1', 'align-items-center');
        listItem.appendChild(input);
        listItem.appendChild(span);

        return listItem;
    }

    _permissionItemOnClick(event) {
        const target = event.target;
        const currentTarget = event.currentTarget;
        const checkBox = currentTarget.querySelector('input');

        if (target.type !== 'checkbox')
            checkBox.checked = !checkBox.checked;

        currentTarget.classList.toggle('selected', checkBox.checked);
    }

    _companyAdminSetup(user) {
        if (this._userCompanyIdsNotBelongingToEditor(user)) {
            this._modalContentRole.disabled = true;
            this._roleLabel.querySelector('span').removeAttribute('hidden');
            this._companyLabel.querySelector('span').removeAttribute('hidden');
            this._accountLabel.querySelector('span').removeAttribute('hidden');
            this._accountStatusCheckbox.disabled = true;
        }
    }

    _statusMessageUpdate() {
        this._statusMessage.textContent = this._accountStatusCheckbox.checked ? "User will be Enabled." : "User will be Disabled.";
    }

    _showDisabledCompanyErrorSpan() {
        this._companiesErrorLabel.textContent = 'Please contact your county administrator to change this setting.';
        this._companiesErrorLabel.toggleAttribute('hidden', false);
    }

    _saveUser() {
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._saveButton.setSpinner(true);
        const userPermissions =
            Array.from(this._permissionsContainer.querySelectorAll('.permission-item > input'))
                .filter(x => x.checked)
                .map(x => Number(x.value));
        this._model.IsActive = this._accountStatusCheckbox.checked;
        this._model.DisplayName = this._modalContentName.value;
        this._model.Role = this._modalContentRole.value.replace("Jail", "County");
        this._model.EmployeeId = this._modalContentEmployeeId.value;

        if ((!this._model.IsCounty && !this._expandRoleOptionsFeatureFlagTemp) || (this._model.Role.includes("Bond Company") && this._expandRoleOptionsFeatureFlagTemp)) {
            let tableData = this._userBondCompaniesConfiguration.getTableData();
            this._model.BondCompanyIds = Array.from(tableData.filter(x => x.CompanySelected).map(x => parseInt(x.BondCompanyId)));
            this._model.UserBondCompanyConfigurations = tableData.map(x => ({
                BondCompanyId: x.BondCompanyId,
                EnforceLimit: x.EnforceLimitSelected,
                Limit: x.Limit,
                License: x.License
            }));
        }

        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest('POST', '/Users/Save', { model: this._model, permissions: userPermissions }, this._userCallBack.bind(this));
    }

    _userCallBack(message, isSuccess) {
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        this._saveButton.setSpinner(false);
        if (!isSuccess) {
            this._warningMessage.textContent = message;
            return;
        }
        const event = new CustomEvent('manage-user-update-grid', { 'detail': this._model});
        this.dispatchEvent(event);
        this._closeModal();
        this._showViewAlert(`Successfully updated user - ${this._model.DisplayName}.`, isSuccess);
    }

    _restModal() {
        this._accountStatusCheckbox.disabled = false;
        this._modalContentRole.disabled = false;
        this._saveButton.removeAttribute("disabled");
        this._statusMessage.textContent = "";
        this._roleErrorLabel.textContent = "";
        this._companiesErrorLabel.textContent = '';
        this._companiesErrorLabel.toggleAttribute('hidden', true);
        this._roleLabel.querySelector('span').toggleAttribute('hidden', true);
        this._roleLabel.removeAttribute("title");
        this._companyLabel.querySelector('span').toggleAttribute('hidden', true);
        this._companyLabel.removeAttribute("title");
        this._accountLabel.querySelector('span').toggleAttribute('hidden', true);
        this._accountLabel.removeAttribute("title");
    }

    _setRoles() {
        const roleType = this._model.IsCounty ? "Jail" : "Bond Company";
        if (this._expandRoleOptionsFeatureFlagTemp && this._editorIsCounty) {
            this._modalOption1.value = this._modalOption1.textContent = "Bond Company Administrator";
            this._modalOption2.value = this._modalOption2.textContent = "Bond Company User";
            this._modalOption3.value = this._modalOption3.textContent = "Jail Administrator";
            this._modalOption4.value = this._modalOption4.textContent = "Jail User";
        } else {
            this._modalOption1.value = this._modalOption1.textContent = `${roleType} Administrator`;
            this._modalOption2.value = this._modalOption2.textContent = `${roleType} User`;
        }
        this._modalContentRole.value = this._model.Role;
    }

    _userCompanyIdsNotBelongingToEditor(user) {
        return Array.from(user.BondCompanyIds).filter(x => !this._editorBondCompanies.includes(x)).length > 0;
    }

    _setInitialStatusState() {
        this._accountStatusCheckbox.checked = this._model.IsActive;
    }

    _statusMessageDisabled() {
        this._statusMessage.textContent = this._contactCountyMessage;
    }

    _showViewAlert(message, isSuccess) {
        const event = new CustomEvent('show-alert-view', { detail: { 'message': message, 'isSuccess': isSuccess } });
        this.dispatchEvent(event);
    }
}
customElements.define('manage-user-modal', ManageUserModal);