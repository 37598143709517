import chargeStatus from '../Enumerations/ChargeStatus';
import rosterStatus from '../Enumerations/RosterStatus';
import { generateSmartSearchTooltip, generateSmartSearchPlaceholder } from '../SmartSearchExtensions';
import { dollarFieldFormatter, dateFormatter } from '../BootstrapTableExtensions.js';

export default class RosterPage {
    constructor(tempMessage, tempMessageType) {
        this._tempMessage = tempMessage;
        this._tempMessageType = tempMessageType;
        this._pageTools = new PageTools();
        this._alert = document.querySelector('.alert');
        this._alertText = document.getElementById('alert-text');
        this._form = document.getElementById('new-application-form');
        this._formJailIdInput = document.getElementById('form-jail-id');
        this._syncFailureDialog = document.getElementById('sync-failure-dialog');
        this._smartSearch = document.querySelector('smart-search');
        this._smartSearchErrorMessage = document.getElementById('smart-search-error');
        this._rosterPageErrorMessage = document.getElementById('roster-error-message');
        this._selectedJailId = null;
        this._continueOnSyncFailure = null;
        this._hasSkipPermission = null;
        this._isCountyUser = null;
        this._tenantName = null;
        this._tenantSmartSearchConfig = null;
        this._renderRefreshButton = null;
        this._setTempAlert();
        this._syncFailureDialog.addEventListener('continue', this._continueApplication.bind(this));
        this._syncFailureDialog.addEventListener('retry', this._retryCreateApplication.bind(this));
        if (this._smartSearch) {
            this._smartSearch.addEventListener('success', this._getSmartSearchData.bind(this));
            this._smartSearch.addEventListener('failure', this._smartSearchDataFailure.bind(this));
            this._smartSearch.addEventListener('beginsearchrequest', this._onSearchRefresh.bind(this));
        }
        this._bootstrapTable = document.getElementById('bootstrap-table');
        this._table = this._bootstrapTable.shadowRoot.getElementById('table');
        this._$table = $(this._table);
        this._subTableColumns = [];
        this._dataErrorTooltipIcon = this._dataErrorTooltipIcon.bind(this);
    }

    set tenantName(value) {
        this._tenantName = value;
    }

    set continueOnSyncFailure(value) {
        this._continueOnSyncFailure = value;
    }

    set hasSkipPermission(value) {
        this._hasSkipPermission = value;
    }

    set isCountyUser(value) {
        this._isCountyUser = value;
    }

    set isSysAdmin(value) {
        this._isSysAdmin = value;
    }

    set hasBookingSheetFeatureFlag(value) {
        this._hasBookingSheetFeatureFlag = value;
    }

    set tenantSmartSearchConfig(value) {
        this._tenantSmartSearchConfig = value;
        this._setToolTip();
        this._setSmartSearchPlaceholder();
    }

    set renderRefreshButton(value) {
        this._renderRefreshButton = value;
    }

    _setupCreateApplicationButtonListenersForElement(root) {
        root.querySelectorAll('action-button[jailid].create-application')
            .forEach(x => x.addEventListener('click', this._createApplication.bind(this)));
        root.querySelectorAll('action-button[jailid].btn-outline-primary')
            .forEach(x => x.addEventListener('click', this._refresh.bind(this)));
        root.querySelectorAll('action-button[jailid].view-booking')
            .forEach(x => x.addEventListener('click', this._viewBooking.bind(this)));
    }

    _toggleRosterErrorMessage(isError) {
        if (isError) {
            this._rosterPageErrorMessage.showErrorMessage("There was a problem completing your request, please check your internet connection and try again. If this problem persists contact <contact-link> eBONDS™ support</contact-link>.", true);
        } else
            this._rosterPageErrorMessage.clearMessage();
    }

    _createApplication(event) {
        const currentTarget = this._getCurrentTargetAndToggleTriggers(event);

        const xhrWrapper = new XhrWrapper();

        let createApplicationSyncCallback = this._createApplicationSyncCallback.bind(this, currentTarget);

        xhrWrapper.makeRequest('POST',
            '/Application/SyncInmateJailing',
            { jailId: this._selectedJailId },
            createApplicationSyncCallback
        );
    }

    _refresh(event) {
        const currentTarget = this._getCurrentTargetAndToggleTriggers(event);
        const xhrWrapper = new XhrWrapper();

        this._$table.bootstrapTable('collapseRowByUniqueId', this._convertJailIdToUniqueId(this._selectedJailId));
        const refreshSyncCallback = this._bootstrapRefreshSyncCallback.bind(this, currentTarget);

        xhrWrapper.makeRequest('POST',
            '/Application/SyncInmateJailing',
            { jailId: this._selectedJailId },
            refreshSyncCallback
        );
    }

    _viewBooking(event) {
        const currentTarget = this._getCurrentTargetAndToggleTriggers(event);
        const xhrWrapper = new XhrWrapper();

        const viewBookingSyncCallback = this._viewBookingSyncCallback.bind(this, currentTarget);

        xhrWrapper.makeRequest('POST',
            '/Application/SyncInmateJailing',
            { jailId: this._selectedJailId },
            viewBookingSyncCallback
        );
    }

    _viewBookingSyncCallback(buttonElement, response, isSuccess) {
        if (!isSuccess) {
            this._openSyncFailureDialog(buttonElement, response, true);
        }

        this._pageTools.toggleTriggers(this._table, false);
        this._pageTools.openLinkInNewTab(`/Roster/BookingSheet/${encodeURIComponent(this._selectedJailId)}`);

        const icon = this._table.querySelector(`tr[data-uniqueid="${this._convertJailIdToEscapedQuerySelector(this._selectedJailId)}"] i`);
        const inmate = this._pageTools.tryParseJson(response)[0];
        if (isSuccess && inmate) {
            inmate.UniqueId = this._convertJailIdToUniqueId(inmate.JailID);
            if (icon.classList.contains("fa-minus")) {
                this._$table.bootstrapTable('collapseRowByUniqueId', this._convertJailIdToUniqueId(this._selectedJailId));
            }
            this._updateInmateTableData(inmate);
            this._$table.bootstrapTable('expandRowByUniqueId', inmate.UniqueId);
        }
    }

    _getCurrentTargetAndToggleTriggers(event) {
        const target = event.currentTarget;
        const jailId = target.getAttribute('jailid');
        this._pageTools.toggleTriggers(this._table, true);
        this._selectedJailId = jailId;
        return target;
    }

    _continueApplication(event) {
        this._submitApplicationForm(event.detail);
    }

    _retryCreateApplication(event) {
        event.detail.buttonElement.click();
    }

    _submitApplicationForm(jailId) {
        this._formJailIdInput.value = jailId;
        this._form.submit();
    }

    _openSyncFailureDialog(buttonElement, response, allowContinueOnSyncFailure) {
        this._pageTools.toggleTriggers(this._table, false);
        const responseObj = this._pageTools.tryParseJson(response);
        if (responseObj) {
            const dialogInitializationObj = {
                hasSkipPermission: this._hasSkipPermission,
                continueOnSyncFailure: this._continueOnSyncFailure && allowContinueOnSyncFailure,
                inmateFN: responseObj.Inmate.InmateFN,
                inmateMI: responseObj.Inmate.InmateMI,
                inmateLN: responseObj.Inmate.InmateLN,
                bookingNumber: responseObj.Inmate.BookNumber,
                jailId: this._selectedJailId,
                buttonElementToRetry: buttonElement,
                lastSyncedTime: responseObj.LastSyncedTime,
                isOdysseyFailure: responseObj.IsOdysseyFailure,
                tenantPhoneNumber: responseObj.TenantPhoneNumber
            };
            this._syncFailureDialog.openDialog(dialogInitializationObj);
        } else {
            this._openAlert('There was a problem completing your request. Please contact eBONDS™ Support.',
                'alert-danger');
        }
    }

    _createApplicationSyncCallback(buttonElement, response, isSuccess) {
        if (!isSuccess) {
            if (response === "There was an error attempting to contact the server.") {
                this._toggleRosterErrorMessage(true);
            } else {
                this._openSyncFailureDialog(buttonElement, response, true);
            }
            return;
        }
        this._submitApplicationForm(this._selectedJailId);
    }

    _bootstrapRefreshSyncCallback(buttonElement, response, isSuccess) {
        if (!isSuccess) {
            this._openSyncFailureDialog(buttonElement, response, false);
            return;
        }
        const inmateArray = this._pageTools.tryParseJson(response);
        const inmate = inmateArray[0];

        if (!inmate) {
            this._pageTools.toggleTriggers(this._table, false);
            return;
        }

        inmate.UniqueId = this._convertJailIdToUniqueId(inmate.JailID);

        this._updateInmateTableData(inmate);
        this._$table.bootstrapTable('expandRowByUniqueId', inmate.UniqueId);
    }

    _updateInmateTableData(inmate) {
        this._$table.bootstrapTable('updateByUniqueId', {
            id: inmate.UniqueId,
            row: {
                InmateFullNameRev: inmate.InmateFullNameRev,
                LocationNode: inmate.LocationNode,
                DOB: inmate.DOB,
                SONumber: inmate.SONumber,
                IsCreateApplicationButtonEnabled: inmate.IsCreateApplicationButtonEnabled,
                HasHold: inmate.HasHold,
                HasDataEntryError: inmate.HasDataEntryError,
                OverallStatus: inmate.OverallStatus
            }
        });
    }

    _resetAlert() {
        this._alert.classList.remove('alert-success', 'alert-danger', 'alert-warning');
        this._alertText.textContent = '';
    }

    _openAlert(message, messageType) {
        this._resetAlert();
        this._alert.classList.add(messageType);
        this._alertText.textContent = message;
        this._alert.classList.remove('hidden');
    }

    _setTempAlert() {
        if ((this._tempMessage && this._tempMessageType))
            this._openAlert(this._tempMessage, this._tempMessageType);
    }

    _getSmartSearchData(data) {
        const model = JSON.parse(data.detail).map(x => ({ ...x, UniqueId: this._convertJailIdToUniqueId(x.JailID) }));
        this._bootstrapTable.smartSearch = true;
        this._bootstrapTable.tableFullData = model;

        this._bootstrapTable.refreshTableData();
        this._$table.bootstrapTable('hideLoading');
        // Bootstrap expand row functions are not working as expected in this context, so trigger click event on the plus icon
        if (model.length === 1) {
            const expandButton = this._table.querySelector('tbody > tr > td > a > i.fa-plus');
            if (expandButton)
                expandButton.click();
        }
    }

    _smartSearchDataFailure() {
        this._smartSearchErrorMessage.toggleAttribute('hidden', false);
        this._smartSearchErrorMessage.innerHTML =
            'There was an error processing your request.' +
            ' Please try again, and if the problem persists, <contact-link>contact eBONDS™ Support</contact-link>.';
        
        this._bootstrapTable.refreshTableData();
        this._$table.bootstrapTable('hideLoading');
    }

    _onSearchRefresh() {
        this._$table.bootstrapTable('showLoading');
        this._smartSearchErrorMessage.toggleAttribute('hidden', true);
    }

    _setToolTip() {
        const config = this._tenantSmartSearchConfig;
        this._smartSearch.toolTip = generateSmartSearchTooltip(
            config.SmartSearchMinimumFirstNameLength,
            config.SmartSearchMinimumLastNameLength,
            config.SmartSearchAllowDobSearch,
            config.SmartSearchAllowBookingNumberSearch,
            config.SmartSearchAllowSoNumberSearch
        );
    }

    _setSmartSearchPlaceholder() {
        const config = this._tenantSmartSearchConfig;
        this._smartSearch.setSmartSearchPlaceHolder = generateSmartSearchPlaceholder(
            config.SmartSearchAllowDobSearch,
            config.SmartSearchAllowBookingNumberSearch,
            config.SmartSearchAllowSoNumberSearch
        );
    }

    _bootstrapOverallStatusFormatter(value, row) {
        const status = rosterStatus[`${value}`];
        if (status === rosterStatus.dataErrors)
            return this._dataErrorTooltipIcon(row);
        return status ? status.string : value;
    }

    _filterOverallStatusFormatter(value) {
        // Comparable regex of /<.*?>/g is vulnerable to ReDoS and the match all character . was swapped for a negative group of [^<>] that is equivalent in our scenario
        // This also means the lazy modifier can be removed since it won't match >'s
        // https://rules.sonarsource.com/javascript/type/security%20hotspot/rspec-5852/
        // https://devina.io/redos-checker
        return value.replaceAll(/<[^<>]*>/g, "").trim();
    }

    _dataErrorTooltipIcon(row) {
        const dataError = rosterStatus.dataErrors.string;
        let title;
        if (this._isCountyUser) {
            const errors = row.Errors;
            let errorList = "";
            for (const element of errors) {
                errorList += element.Error;
            }
            title = `As of ${window.DateFormatter.getTenantFormattedDate(errors[0].LastSyncTime)} this inmate was flagged with the following jail management system data entry error: ${errorList}.`;
        }
        else {
            title = `As of ${window.DateFormatter.getTenantFormattedDate(row.Errors[0].LastSyncTime)} this inmate was flagged with a jail management system data entry error, please contact ${ this._tenantName } for more information.`;
        }
        return `<span>${dataError} </span><span id="title-tooltip-data-error" class="fa fa-info-circle" data-toggle="tooltip" data-html="true" title="${title}"></span>`;
    }

    _bootstrapChargeStatusFormatter(value, row, index, field) {
        return chargeStatus[`${value}`] !== undefined ? chargeStatus[`${value}`].string : value;
    }

    _rowStyleFormatter(row, index) {
        let classes = '';
        let status = '';
        if (row.hasOwnProperty('OverallStatus')) {
            status = row.OverallStatus;
        }
        if (row.hasOwnProperty('ChargeStatus')) {
            status = row.ChargeStatus;
        }
        switch (status) {
            case 'available':
                classes = 'charge-status-available';
                break;
            case 'completed':
                break;
            case 'holdWithoutBond':
                classes = 'charge-status-hold-without-bond';
                break;
            case 'inBookout':
            case 'inProgress':
            case 'inReview':
                classes = 'charge-status-in-progress';
                break;
            case 'awaitingCharges':
                classes = 'charge-status-awaiting-charges';
                break;
            case 'notBondable':
            case 'dataErrors':
                classes = 'charge-status-not-bondable';
                break;
        }
        return {
            classes: classes
        };
    }

    _detailViewFormatter(index, row, element) {
        const loader = "<div class='text-center spinner d-none'><div class='spinner-border' role='status'><span class='sr-only'>Loading...</span></div></div>";
        const subTable = `<div class='wrapper'>
            <div class="card">
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#booking-tab-${index}">Booking</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#inmate-${index}">Inmate Information</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane booking-tab-content active" id="booking-tab-${index}">
                        ${loader}
                        <table class="booking-table" id="bootstrapSubTable-${index}"></table>
                    </div>
                    <div class="tab-pane inmate-tab-content p-1" id="inmate-${index}">
                        ${loader}
                    </div>
                </div>
            </div>
        </div>`;
        const tdElement = element[0];
        tdElement.innerHTML = subTable;

        let spinner = tdElement.querySelector('.booking-tab-content .spinner');
        const inmateSpinner = tdElement.querySelector('.inmate-tab-content .spinner');

        spinner.classList.remove('d-none');
        inmateSpinner.classList.remove('d-none');
        
        // Append the inmate information to the inmate tab content
        const inmateCallback = function (response, success) {
            if (success) {
                tdElement.querySelector('.inmate-tab-content').innerHTML = response;
            } else {
                console.error(response);
            }
        };
        const xhrWrapper1 = new XhrWrapper();
        xhrWrapper1.makeRequest('GET',
            `/Roster/GetInmateInformationTabStripContent?soNumber=${encodeURIComponent(row.SONumber)}`,
            null,
            inmateCallback);

        const $table = element.find('table.booking-table');
     
        const xhrWrapper2 = new XhrWrapper();
        const subTableUrl = '/Roster/Roster_Booking/';
        xhrWrapper2.makeRequest('GET',
            subTableUrl + encodeURIComponent(row.JailID),
            null,
            detailViewFormatterCallback.bind(this)
         );

        function detailViewFormatterCallback(response, isSuccess) {
            if (!isSuccess) {
                tdElement.innerHTML = '';
                this._$table.bootstrapTable('collapseRowByUniqueId', row.UniqueId);
                this._toggleRosterErrorMessage(true);
                return;
            }
            
            const subTableDataResponse = JSON.parse(response);
            this._toggleRosterErrorMessage(false);

            $table.bootstrapTable({
                columns: this._subTableColumns,
                data: subTableDataResponse,
                pagination: true,
                pageSize: 5,
                paginationHAlign: 'left',
                paginationDetailHAlign: 'left',
                // by default, pagesize dropdown doesn't work since it is added dynamically,
                // so to fix this we need to bind it to dropdown everytime the table is reset
                onResetView: function() {
                    element.find('.dropdown-toggle').dropdown();
                },
                classes: 'table table-sm table-striped table-bordered',
                rowStyle: this._rowStyleFormatter
            });

            //calling update row causes refresh issues so we call updateCell with reinit false
            const subTableTooltip = element.find('#title-tooltip');
            subTableTooltip.tooltip({ boundary: 'viewport' });

            spinner = tdElement.querySelector('.booking-tab-content .spinner');
            spinner.classList.add('d-none');
            // Get the table from the element variable else it will cause "Unhandled promise rejection: TypeError: $ is not a function" error

            // we need to manually update the HTML to update the backing data. 
            const rowSelector = `tr[data-uniqueid="${this._convertUniqueIdToEscapedQuerySelector(row.UniqueId)}"]`; // Need to convert the quote and then double escape it
            const parentTr = this._table.querySelector(rowSelector);

            //fixes create application button html
            const buttonContainer = parentTr.querySelector('td:last-child')
            buttonContainer.innerHTML = this._actionFormatter(null, row, null, null);
            this._setupCreateApplicationButtonListenersForElement(buttonContainer);

            //fixes status container html
            const statusContainer = parentTr.querySelector(`td:nth-last-child(2)`);
            statusContainer.innerHTML = this._bootstrapOverallStatusFormatter(row.OverallStatus, row);

            //fixes row color
            parentTr.className = this._rowStyleFormatter(row).classes;

            const tooltip = parentTr.querySelector("#title-tooltip-data-error");
            $(tooltip).tooltip();
         }
    }

    _actionFormatter(value, row, index, field) {
        const quotedJailId = row.UniqueId;
        let disabled = '';
        let trigger = 'trigger';
        if (!row.IsCreateApplicationButtonEnabled) {
            disabled = 'disabled';
            trigger = '';
        }
        let buttonString = `<div class='d-flex align-items-center justify-content-center'>`;
        if ((!this._isCountyUser || this._isSysAdmin) && this._hasBookingSheetFeatureFlag) 
            buttonString += `<action-button class='btn btn-primary mr-4 view-booking' trigger alternatebuttontext='Loading Booking' jailId="${quotedJailId}" data-shadow-disabled>View Booking</action-button>`;
        
        if (this._userHasCreateApplicationPermission)
            buttonString += `<action-button class='btn btn-primary create-application' ${trigger} alternatebuttontext='Loading Inmate' jailid="${quotedJailId}" ${disabled} data-shadow-disabled>Create Application</action-button>`;

        if (this._renderRefreshButton)
            buttonString += `<action-button class='btn btn-outline-primary ml-4 refresh-btn' trigger alternateButtonText='Refreshing Inmate' jailid="${quotedJailId}" data-shadow-disabled>Refresh</action-button>`;
        buttonString += `</div>`;

        return buttonString;
    }

    initBootstrapTable(data, userHasDispositionViewPermission, userHasCreateApplicationPermission, hasIssuingAuthority) {
        this._userHasCreateApplicationPermission = userHasCreateApplicationPermission;
        const bondTypeTooltip =
            '<span id="title-tooltip" class="fa fa-info-circle ml-1" data-toggle="tooltip" data-html="true" title="This is the bond type assigned in the jail management system."></span>';
        // Created custom events so that _refresh & _createApplication functions can be called using this
        const operateEvents = {
            'click .view-booking': function(e, value, row, index) {
                const viewEvent = new CustomEvent('viewClick',
                    {
                        detail: {
                            event: e
                        }
                    }
                );
                const table = e.currentTarget.closest('table');
                table.dispatchEvent(viewEvent);
            },
            'click .create-application': function(e, value, row, index) {
                const createEvent = new CustomEvent('createClick',
                    {
                        detail: {
                            event: e
                        }
                    }
                );
                const table = e.currentTarget.closest('table');
                table.dispatchEvent(createEvent);
            },
            'click .refresh-btn': function(e, value, row, index) {
                const refreshEvent = new CustomEvent('refreshClick',
                    {
                        detail: {
                            event: e
                        }
                    }
                );
                const table = e.currentTarget.closest('table');
                table.dispatchEvent(refreshEvent);
            }
        };
        const tableColumns = [
            {
                title: 'Inmate',
                field: 'InmateFullNameRev',
                type: 'Text',
                escape: true
            },
            {
                title: 'Location',
                field: 'LocationNode',
                type: 'Text',
                escape: true
            },
            {
                title: 'Date of Birth',
                field: 'DOB',
                type: 'Date',
                escape: true,
                formatter: dateFormatter
            },
            {
                title: 'SO Number',
                field: 'SONumber',
                type: 'Text',
                escape: true
            },
            {
                title: 'Status',
                field: 'OverallStatus',
                type: 'Text',
                escape: true,
                formatter: this._bootstrapOverallStatusFormatter.bind(this),
                filterFormatter: this._filterOverallStatusFormatter.bind(this) 
            },
            {
                title: '',
                field: '',
                type: 'SafeHTML',
                searchable: false,
                escape: false,
                formatter: this._actionFormatter.bind(this),
                events: operateEvents,
                printIgnore: true
            }
        ];
        this._subTableColumns = [
            {
                title: 'Arrest Date',
                field: 'ArrestDate'
            },
            {
                title: 'Booking Number',
                field: 'BookNumber'
            },
            {
                title: 'Offense Code',
                field: 'OffenseCode'
            },
            {
                title: 'Degree',
                field: 'OffenseDegree'
            },
            {
                title: 'Offense Description',
                field: 'OffenseDesc'
            }
        ];

        if (hasIssuingAuthority) {
            this._subTableColumns.push({
                title: 'Issuing Authority',
                field: 'IssuingAuthority'
            });
        }

        //https://stackoverflow.com/a/9650855
        this._subTableColumns.push.apply(this._subTableColumns,
            [
                {
                    title: `Bond Type ${bondTypeTooltip}`,
                    field: 'BondType'
                },
                {
                    title: 'Bond Description',
                    field: 'BondDesc'
                },
                {
                    title: 'Bond Amount',
                    field: 'BondAmt',
                    formatter: dollarFieldFormatter
                },
                {
                    title: 'Status',
                    field: 'ChargeStatus',
                    formatter: this._bootstrapChargeStatusFormatter.bind(this)
                }
            ]);

        let dynamicSubHeaders = [
            {
                title: 'Charge Disposition',
                field: 'ChargeDispositionCode'
            },
            {
                title: 'Disposition Date',
                field: 'ChargeDispositionDate'
            }
        ];
        if (userHasDispositionViewPermission)
            this._subTableColumns.splice(5, 0, ...dynamicSubHeaders);

        const tableConfig = {
            uniqueId: 'UniqueId',
            tableColumns: tableColumns,
            subTableColumns: this._subTableColumns,
            pageSize: 10,
            detailView: true,
            mainTableHeight: 600,
            print: false,
            export: false,
            exportFilename: 'Jail Roster',
            detailViewFormatter: this._detailViewFormatter.bind(this),
            rowStyleFormatter: this._rowStyleFormatter,
            restoreFilters: true,
            tableName: "JailRoster",
            paginationDetails: true,
            columnFilters: true,
            sortable: true,
            toolbar: true,
            tableAlertMessage: true
        };

        // Initialize the bootstrap table component
        this._bootstrapTable.initTableData({
            bootstrapTableConfig: tableConfig,
            tableData: data.map(x => ({ ...x, UniqueId: this._convertJailIdToUniqueId(x.JailID) }))
        });

        this._setupTableEventListeners();
        this._$table.on('reset-view.bs.table', function (event) {
            if (event.target != event.currentTarget)
                return; // Skip bubbled events

            const table = event.currentTarget;
            // Workaround since bootstrap tabs didn't work properly inside the table
            // So attach the tab event handler everytime the table is reset like appending data to table when scrolling/subtable is loaded
            table.querySelectorAll('a[data-toggle="tab"]').forEach((tab) => {
                tab.addEventListener('click', (e) => {
                    e.preventDefault();
                    const target = e.currentTarget;
                    $(target).tab('show');
                    const id = target.getAttribute('href');
                    if (!target.closest('.wrapper').querySelector(`.tab-content ${id}`).classList.contains('active')) {
                        target.closest('.wrapper').querySelectorAll('.tab-content .active').forEach((tabContent) => {
                            tabContent.classList.remove('active');
                        });
                        target.closest('.wrapper').querySelector(`.tab-content ${id}`).classList.add('active');
                    }
                }, true);
            });
        });

        // https://github.com/wenzhixin/bootstrap-table/issues/6447
        // Workaround for row-details losing event subscriptions when a different row is refreshed
        const expandedRows = {};
        this._$table.on('pre-body.bs.table', this._preBodyCallback.bind(this, expandedRows));
        this._$table.on('post-body.bs.table', this._postBodyCallback.bind(this, expandedRows));
    }

    _setupTableEventListeners() {
        this._table.addEventListener('refreshClick', e => this._refresh(
            (e.detail !== undefined && e.detail.event !== undefined) ? e.detail.event : {}
        ));
        this._table.addEventListener('createClick', e => this._createApplication(
            (e.detail !== undefined && e.detail.event !== undefined) ? e.detail.event : {}
        ));
        this._table.addEventListener('viewClick', e => this._viewBooking(
            (e.detail !== undefined && e.detail.event !== undefined) ? e.detail.event : {}
        ));
    }

    _preBodyCallback(rows, event) {
        // skip bubbled events from nested tables
        if (event.target != event.currentTarget) return;

        this._$table.find(">tbody>tr.detail-view").each(function (index, tr) {
            const $tr = $(tr);
            const row_uniqueid = $tr.prev().data('uniqueid');
            rows[row_uniqueid] = $(document.createDocumentFragment());
            rows[row_uniqueid].append($tr);
        });
    }

    _postBodyCallback(rows, event) {
        // skip bubbled events from nested tables
        if (event.target != event.currentTarget) return;

        const $table = this._$table;
        this._$table.find(">tbody>tr").each(function(index, tr) {
            const $tr = $(tr);
            const row_uniqueid = $tr.data('uniqueid');
            if (rows[row_uniqueid] && rows[row_uniqueid].children().length) {
                $tr.after(rows[row_uniqueid]);
                $table.bootstrapTable('expandRowByUniqueId',
                    row_uniqueid); // Fixes expand icon which breaks due to removing the row and adding it back
            }
        });

        rows = {};

        const tooltips = this._table.querySelectorAll('#title-tooltip-data-error');
        $(tooltips).tooltip();
    }

    _convertUniqueIdToEscapedQuerySelector(uniqueId) {
        return uniqueId.replaceAll('&quot;', '\\"');
    }

    _convertJailIdToUniqueId(jailId) {
        return jailId.replaceAll('"', '&quot;');
    }

    _convertJailIdToEscapedQuerySelector(jailId) {
        return jailId.replaceAll('"', '\\"');
    }
}
window.RosterPage = RosterPage;