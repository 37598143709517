import template from './AllPaidSearch.html';
import { debounce } from '../JavaScriptFunctions';

import '../ActionButton';
import '../SmartSearch.js';


class AllPaidSearch extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = template;
        this._model = null;
        this._plcCode = null;
        this._pageTools = new PageTools();
        this._jailName = this.querySelector('.stjaillabel');
        this._searchBar = this.querySelector('.stsearchbox');
        this._table = this.querySelector('.sttTable');
        this._loadingSpinner = this.querySelector('#loadingcontainer');
        this._noInmatesFound = this.querySelector('#noinmatesfound');
        this._tableBody = this.querySelector('tbody');
        this._searchResultsCount = this.querySelector('.stsearchresults');
        this.submit = this.submit.bind(this);
        this.debouncedSubmit = debounce(this.submit, 500);
        this._onSearchSuccess = this._onSearchSuccess.bind(this);
        this._onSearchFailure = this._onSearchFailure.bind(this);
        this._onKeyUp = this._onKeyUp.bind(this);
        this._onSelectInmateClick = this._onSelectInmateClick.bind(this);
        this._mugshots = null;
        this._inmateInfo = null
        this._searchBar.addEventListener('success', this._onSearchSuccess);
        this._searchBar.addEventListener('failure', this._onSearchFailure);
        this._searchBar.addEventListener('beginsearchrequest', this._onSearchBegin);
        this._searchBar.addEventListener('input', this.debouncedSubmit);
        this._searchBar.addEventListener('keyup', this._onKeyUp);
    }

    disconnectedCallback() {
        this._searchBar.removeEventListener('success', this._onSearchSuccess);
        this._searchBar.removeEventListener('failure', this._onSearchFailure);
        this._searchBar.removeEventListener('beginsearchrequest', this._onSearchRefresh);
        this._searchBar.removeEventListener('input', this.debouncedSubmit);
        this._searchBar.removeEventListener('keyup', this._onKeyUp);
    }

    set model(value) {
        this._model = value;

        const urlParams = new URLSearchParams(this._pageTools.getLocation().search);
        const plcCode = urlParams.get('plc-code');
        const inmateName = urlParams.get('inmate-name');

        this._plcCode = plcCode;

        if (inmateName) {
            this._searchBar.value = inmateName;
            this.submit();
        }
    }

    _onSearchSuccess(detail) {
        const data = JSON.parse(detail);
        if (data.length === 0) {
            this._toggleNoInmatesFound(true);
            this._toggleLoading(false);
            return;
        };
        this._toggleNoInmatesFound(false);
        this._createRows(data);
        this._searchResultsCount.textContent = `${data.length} people found`;
        //work around for hard coded sticky headers
    }

    _createRows(data) {
        this._toggleLoading(false);

        data.forEach(inmate => {
            const nameRow = document.createElement('tr');
            const nameCell = document.createElement('td');
            nameCell.classList.add('stnamecell');
            nameCell.classList.add('text-center');
            nameCell.innerHTML = `<strong>${inmate.inmateFullNameRev}</strong>`;
            nameRow.appendChild(nameCell);
            const balanceCell = document.createElement('td');
            balanceCell.classList.add('stbalancecell');
            balanceCell.innerHTML = `<strong>${this._formatRemainingBalance(inmate.remainingBalance)}</strong>`;
            nameRow.appendChild(balanceCell);
            const buttonCell = document.createElement('td');
            buttonCell.classList.add('stbuttoncell');
            if (inmate.remainingBalance === 0) {
                const disabledButton = document.createElement('action-button');
                disabledButton.classList.add('stbutton', 'disabled');
                disabledButton.toggleAttribute("disabled", true);
                disabledButton.textContent = 'Select';
                buttonCell.appendChild(disabledButton);
            } else {
                const selectButton = document.createElement('action-button');
                selectButton.setAttribute('alternatebuttontext', '');
                selectButton.toggleAttribute('trigger', true);
                selectButton.classList.add('stbutton');
                selectButton.textContent = 'Select';
                buttonCell.appendChild(selectButton);
                selectButton.setAttribute("data-jail-id", inmate.jailId);
                selectButton.setAttribute("data-booking-number", inmate.bookingNumber);
                selectButton.addEventListener('click', this._onSelectInmateClick);
            }

            nameRow.appendChild(buttonCell);
            const inmateInformationRow = document.createElement('tr');
            inmateInformationRow.classList.add('stsecondrow');
            const mugshots = document.createElement('mugshot-carousel');
            mugshots.tenantId = this._model.TenantId;
            mugshots.mugshots = inmate.mugshots;

            const tr = document.createElement('tr');
            tr.classList.add('stsecondrow');
            const imageCell = document.createElement('td');
            imageCell.classList.add('stimagecell');
            imageCell.appendChild(mugshots);
            inmateInformationRow.appendChild(imageCell);
            inmateInformationRow.appendChild(this._statCellFormatter(inmate));
            this._tableBody.appendChild(nameRow);
            this._tableBody.appendChild(inmateInformationRow);
        });
    }

    _onSearchFailure() {
        this._toggleLoading(false);
        this._toggleNoInmatesFound(true);
    }

    _statCellFormatter(inmate) {
        const statCell = document.createElement('td');
        statCell.classList.add('ststatcell');
        statCell.setAttribute("colspan", 2);
        statCell.appendChild(createSpan("Booking Date: ", inmate.bookingDate));
        statCell.appendChild(createSpan("Inmate ID: ", inmate.soNumber));
        statCell.appendChild(createSpan("Booking #: ", inmate.bookingNumber));
        statCell.appendChild(createSpan("DOB: ", inmate.dob));
        const raceGender = document.createElement('strong');
        raceGender.textContent = 'Race/Gender: ';
        statCell.appendChild(raceGender);
        const racegenderData = document.createElement('span');
        racegenderData.textContent = `${inmate.race}/${inmate.gender} `;
        statCell.appendChild(racegenderData);
        const raceGenderBr = document.createElement('br');
        statCell.appendChild(raceGenderBr);
        const weightCell = document.createElement('strong');
        weightCell.textContent = "Weight: ";
        statCell.appendChild(weightCell);
        const weightData = document.createElement('span');
        weightData.textContent = inmate.inmateWeight;
        statCell.appendChild(weightData);
        const heightCell = document.createElement('strong');
        heightCell.textContent = " Height: ";
        statCell.appendChild(heightCell);
        const heightSpan = document.createElement('span');
        heightSpan.textContent = `${inmate.inmateHeightFt}' ${inmate.inmateHeightIn}"`;
        statCell.appendChild(heightSpan);
        const heightBR = document.createElement('br');
        statCell.appendChild(heightBR);
       
        function createSpan(label, data) {
            const containingSpan = document.createElement('span');
            const bookingData = document.createElement('strong');
            bookingData.textContent = label;
            const bookingDataSpan = document.createElement('span');
            bookingDataSpan.textContent = data;
            const bookingBr = document.createElement('br');
            containingSpan.appendChild(bookingData);
            containingSpan.appendChild(bookingDataSpan);
            containingSpan.appendChild(bookingBr);
            return containingSpan;
        }
        return statCell;
    }

    _onSelectInmateClick(e) {
        const button = e.currentTarget;
        this._pageTools.toggleTriggers(this);
        const jailId = button.getAttribute('data-jail-id');
        const bookingNumber = button.getAttribute('data-booking-number');
        this._refreshInmate(jailId, this._model.TenantId, bookingNumber);
    }

    submit() {
        this._beginSearch();

        if (this.submitXhrWrapper) {
            this.submitXhrWrapper.abort();
        }
        const event = new Event('beginsearchrequest');
        this.dispatchEvent(event);
        this.submitXhrWrapper = new XhrWrapper();
        let query = "?query=" + encodeURIComponent(this._searchBar.value);
        if (this._additionalQueryParameters)
            query = this._additionalQueryParameters.reduce((value, x) => value + `&${x.name}=${encodeURIComponent(x.value)}`, query);
        this.submitXhrWrapper.makeRequest('GET',
            '/PaymentPortal/InmateSearch' + query + `&tenantId=${encodeURIComponent(this._model.TenantId)}`,
            null,
            this._searchCallback.bind(this));
    }

    _beginSearch() {
        this._tableBody.innerHTML = '';
        this._toggleLoading(true);
        this._toggleNoInmatesFound(false);
    }

    _toggleLoading(isLoading) {
        if (!isLoading) {
            this._loadingSpinner.classList.remove('loadingcontainer');
            this._loadingSpinner.classList.add('hiddenloadingcontainer');
        } else {
            this._loadingSpinner.classList.remove('hiddenloadingcontainer');
            this._loadingSpinner.classList.add('loadingcontainer');
        }
    }

    _toggleNoInmatesFound(isInmates) {
        if (!isInmates) {
            this._noInmatesFound.classList.remove('noinmatesFound');
            this._noInmatesFound.classList.add('hiddennoinmatesFound');
            this._searchResultsCount.textContent = ' 0 people found';
        } else {
            this._noInmatesFound.classList.remove('hiddennoinmatesFound');
            this._noInmatesFound.classList.add('noinmatesFound');
        }
    }

    _onKeyUp(event) {
        if (event.key === "Enter") {
            this.debouncedSubmit(true);
            this.submit();
        }
    }

    _searchCallback(response, status) {
        if (status) {
            this._onSearchSuccess(response);
        } else {
            this._onSearchFailure();
        }
    }
    _formatRemainingBalance(balance) {
        if (balance === 0) {
            return "N/A";
        }

        return this._pageTools.formatNumberToDollarAmount(balance);
    }

    _refreshInmate(jailId, tenantId, bookingNumber) {
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest(
            'POST',
            `/PaymentPortal/RefreshInmate`,
            { tenantId, jailId },
            this._refreshInmateCallback.bind(this, bookingNumber)
        );
    }

    _refreshInmateCallback(bookingNumber, result, isSuccess) {
        if (!isSuccess) {
            console.error(`Inmate refresh for plc ${this._plcCode} and bookingNumber ${bookingNumber} failed.`);
        }
        this._pageTools.redirectToUrl(`/PaymentPortal/Pay?plc-code=${encodeURIComponent(this._plcCode)}&bookingNumber=${encodeURIComponent(bookingNumber)}`);
    }
}
customElements.define('all-paid-search', AllPaidSearch);