import './ChargesUnavailableDialog';
import './PaymentConfirmationDialog';
import './../CardsComponent';
import './../ContactLink';
import './../AlertMessage';

export default class AuthorizePayment {
    constructor(model, message, messageType) {
        this._pageTools = new PageTools();

        this._bondApplicationId = null;
        this._hasConvenienceFee = null;
        this._tenantName = null;
        this._defaultCard = document.querySelector('display-card');
        this._cancelButton = document.getElementById('cancel-button');
        this._useDefaultButton = document.getElementById('use-default-button');
        this._selectACardButton = document.getElementById('select-a-card-button');
        this._useDefaultButton.addEventListener('click', this._useDefaultCard.bind(this));
        this._paymentConfirmationDialog = document.querySelector('payment-confirmation-dialog');
        this._chargesUnavailableModal = document.getElementById('charges-unavailable-dialog');
        this._cards = document.querySelector('cards-component');
        this._alertMessage = document.getElementById('alert-message');
        this._cancelButton.addEventListener('click', this._redirectBack.bind(this));

        this._setModelStates(model);
        if (message && messageType)
            this._showAlert(message, false, messageType === "alert-danger");

        this._cards.addEventListener("show-alert", this._showAlertHandler.bind(this));
        this._cards.addEventListener("clear-alert", this._clearAlertHandler.bind(this));
        this._cards.addEventListener('use-card-onclick', this._useSelectedCard.bind(this));
        this._cards.addEventListener('default-card-selected', this._validateUseDefaultCardButton.bind(this));
    }

    set bondApplicationId(value) {
        this._bondApplicationId = value;
    }

    set hasConvenienceFee(value) {
        if (value === null) {
            const currentLocationHref = this._pageTools.getLocation().href;
            const tryAgainAnchor = `<a style='color: inherit; text-decoration: underline' href='${currentLocationHref}'>here</a>`;
            const contactLink = '<contact-link>contact eBONDS™ Support</contact-link>';
            const htmlMessage = `There was a problem connecting to the payment processor for ${this._tenantName}. Click ${tryAgainAnchor} to try again. If this problem persists, ${contactLink}.`;
            this._showAlert(htmlMessage, true, true);
            this._cards.triggerState(true);
        }
        else
            this._hasConvenienceFee = value;
    }

    set tenantName(value) {
        this._tenantName = value;
    }

    openChargesUnavailableDialog(bondAppId, suretyType) {
        this._chargesUnavailableModal.type = suretyType;
        this._chargesUnavailableModal.open(bondAppId);
    }

    _setModelStates(viewModel) {
        this._showUseDefaultButton(viewModel.DefaultCard);
        this._cards.model = viewModel;
    }

    _showUseDefaultButton(defaultCard) {
        const useCardState = defaultCard.IsDefault && !this._pageTools.checkExpired(defaultCard.ExpirationDate);
        this._useDefaultButton.classList.toggle('hidden', !useCardState);
        this._useDefaultButton.toggleAttribute('disabled', !useCardState);
        this._selectACardButton.classList.toggle('hidden', useCardState);
        this._selectACardButton.toggleAttribute('disabled', useCardState);
    }

    _validateUseDefaultCardButton(event) {
        this._showUseDefaultButton(event.detail.model);
    }

    _showAlertHandler(event) {
        this._showAlert(event.detail.message, false, event.detail.danger);
    }

    _showAlert(message, isInnerHtmlMessage, isError) {
        if (isError)
            this._alertMessage.showErrorMessage(message, isInnerHtmlMessage);
        else
            this._alertMessage.showSuccessMessage(message, isInnerHtmlMessage);
    }

    _redirectBack() {
        this._pageTools.getHistory().back();
    }

    _clearAlertHandler(event) {
        this._clearAlert();
    }

    _clearAlert() {
        this._alertMessage.clearMessage();
    }

    _useDefaultCard() {
        this._pageTools.toggleTriggers(document, true);
        this._useCard(this._cards.model.DefaultCard, document);
    }

    _useSelectedCard(event) {
        this._pageTools.toggleTriggers(event.detail.footerShadowRoot, true);
        this._useCard(event.detail.model, event.detail.footerShadowRoot);
    }

    _useCard(model, elementContainer) {
        this._clearAlert();
        if (this._hasConvenienceFee) {
            const xhrWrapper = new XhrWrapper();
            xhrWrapper.makeRequest(
                'GET',
                `/Application/GetConvenienceFee?id=${this._bondApplicationId}&cardType=${model.CardType}`,
                null,
                this._getConvenienceFeeCallback.bind(this, model, elementContainer)
            );
            return;
        }

        this._pageTools.toggleTriggers(elementContainer, false);
        this._paymentConfirmationDialog.openModal({ detail: { model: model, convenienceFeeAndName: null } });
    }

    _getConvenienceFeeCallback(model, elementContainer, response, isSuccess) {
        this._pageTools.toggleTriggers(elementContainer, false);
        const responseObj = this._pageTools.tryParseJson(response);
        if (!isSuccess || !responseObj) {
            const message = 'There was a failure attempting to obtain payment gateway convenience fees.' +
                ' Please try again, and if the problem persists, <contact-link>contact eBONDS™ Support</contact-link>.';
            this._showAlert(message, true, true);
            this._pageTools.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }

        this._paymentConfirmationDialog.openModal(
            {
                detail: {
                    model: model,
                    convenienceFeeAndName: responseObj,
                    tenantName: this._tenantName
                }
            });
    }
}
window.AuthorizePayment = AuthorizePayment;