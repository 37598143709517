import type { DataTypeDefinition, ValueFormatterLiteParams, ValueParserLiteParams } from "ag-grid-enterprise";

export function getDefaultDateStringDataDefinition<TData>(): DataTypeDefinition<TData>
{
    const iso8601DateFormat: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{7}Z)?$/;
    const shortDateFormat: RegExp = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    // https://www.ag-grid.com/javascript-data-grid/cell-data-types/#overriding-the-pre-defined-cell-data-type-definitions
    // Original is from the provided code of the sample dateString ag grid example table in the url above
    return {
        baseDataType: "dateString",
        extendsDataType: "dateString",
        valueParser: (params: ValueParserLiteParams<TData, string>) =>
            params.newValue != null && shortDateFormat.exec(params.newValue)
                ? params.newValue
                : null,
        valueFormatter: (params: ValueFormatterLiteParams<TData, string>) =>
        {
            if (params.value == null)
                return "";
            else if ((iso8601DateFormat).exec(params.value)) {
                const date = params.value.split("T")[0];
                const dateParts = date.split("-");
                return `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}`;
            }
            return params.value;
        },
        dataTypeMatcher: (value: any) =>
            typeof value === "string" && !!shortDateFormat.exec(value),
        dateParser: (value: string | undefined) => {
            if (value == null || value === "") {
                return undefined;
            }
            if (shortDateFormat.exec(value)) {
                const dateParts = value.split("/");
                return new Date(
                        parseInt(dateParts[2]),
                        parseInt(dateParts[0]) - 1,
                        parseInt(dateParts[1]),
                )
            } else if (iso8601DateFormat.exec(value)) {
                const date = value.split("T")[0];
                const dateParts = date.split("-");
                return new Date(
                    parseInt(dateParts[0]),
                    parseInt(dateParts[1]) - 1,
                    parseInt(dateParts[2]),
                )
            }
            return undefined;
        },
        dateFormatter: (value: Date | undefined) => {
            if (value == null) {
                return undefined;
            }
            const date = String(value.getDate());
            const month = String(value.getMonth() + 1);
            return `${month.length === 1 ? "0" + month : month}/${date.length === 1 ? "0" + date : date}/${value.getFullYear()}`;
        },
    }
}