import "../ActionButton";
import "../AlertMessage";
import "../Grid/BasicGrid";
import bootstrap from "./../../scss/bootstrap-custom.scss";
import template from "./FeatureFlagManagement.html";
import { initializeHtmlElement } from "../HTMLElementExtensions";
import { nullThrow } from "../TypeScriptFunctions";
import type { ActionButton } from "../ActionButton"
import type { AlertMessage } from "../AlertMessage";
import type { BasicGrid } from "../Grid/BasicGrid";
import type { FeatureFlagManagementModel } from "../../types/Library/Common/Models/FeatureFlagManagementModel"
import type { CellValueChangedEvent, EditableCallbackParams, GridApi, GridOptions, ICellRendererParams } from "ag-grid-enterprise";

export class FeatureFlagManagement extends HTMLElement {
    private _gridApi: GridApi<FeatureFlagManagementModel> = <GridApi<FeatureFlagManagementModel>>{};
    private _data: FeatureFlagManagementModel[] | undefined;
    private _pageTools: IPageTools = new PageTools();
    private _alertMessage: AlertMessage;
    private readonly MUTUALLY_EXCLUSIVE_FLAGS: string[][] = [
        ['AllowAmendingBondDocuments', 'AllowEditingBondDocuments']
    ];

    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap], []);
        this._alertMessage = <AlertMessage>nullThrow(this.shadowRoot?.getElementById("alert-message"));
    }

    connectedCallback() {
        const basicGrid = <BasicGrid>nullThrow(this.shadowRoot?.getElementById("grid"));
        const saveButton = <ActionButton>nullThrow(this.shadowRoot?.getElementById("save-button"));
        const cancelButton = <ActionButton>nullThrow(this.shadowRoot?.getElementById("cancel-button"));
        const titleTenant = nullThrow(this.shadowRoot?.getElementById("title-tenant"));
        saveButton.addEventListener("click", this.saveClicked.bind(this));
        cancelButton.addEventListener("click", this.cancelClicked.bind(this));
        titleTenant.textContent = settings.tenant;
        const options: GridOptions<FeatureFlagManagementModel> = {
            defaultColDef: {
                filter: "agTextColumnFilter"
            },
            columnDefs: [
                {
                    headerName: "",
                    field: "Enabled",
                    editable: (params) => this.isEditable(params),
                    suppressAutoSize: true,
                    maxWidth: 45,
                    filter: false
                },
                {
                    field: "FeatureFlag",
                    floatingFilter: true
                },
                {
                    field: "Description",
                    cellRenderer: (params: ICellRendererParams) => params.value,
                    wrapText: true,
                    autoHeight: true,
                    flex: 1,
                    floatingFilter: true
                }
            ],
            onCellValueChanged: this.onCellValueChanged.bind(this),
        };
        this._gridApi = basicGrid.createGrid(options);
        this.refreshData();
    }

    private isEditable(params: EditableCallbackParams<FeatureFlagManagementModel>) {
        for (const flagGroup of this.MUTUALLY_EXCLUSIVE_FLAGS) {
            if (flagGroup.includes(params.data!.FeatureFlag)) {
                const otherFlags = flagGroup.filter(flag => flag !== params.data!.FeatureFlag);
                for (const flag of otherFlags) {
                    if (this._data?.find(item => item.FeatureFlag === flag)?.Enabled) {
                        return false;
                    }
                }
            }
        }
        return true;
    }

    private onCellValueChanged(event: CellValueChangedEvent<FeatureFlagManagementModel>) {
        for (const flagGroup of this.MUTUALLY_EXCLUSIVE_FLAGS) {
            if (flagGroup.includes(event.data.FeatureFlag)) {
                this._gridApi.refreshCells({
                    force: true,
                    columns: ['Enabled'],
                });
                break;
            }
        }
    }

    private refreshData() {
        this._pageTools.toggleTriggers(this.shadowRoot!, true);
        const callback = (response: string, status: boolean) => {
            this._pageTools.toggleTriggers(this.shadowRoot!, false);
            this._gridApi.setGridOption("loading", false);
            if (!status) {
                this._alertMessage.showErrorMessage(response);
                return;
            }
            const data = JSON.parse(response) as FeatureFlagManagementModel[]
            this._data = data;
            this._gridApi.setGridOption("rowData", data);
            this._gridApi.autoSizeColumns(["FeatureFlag"]);
        }

        this._alertMessage.clearMessage();
        this._gridApi.setGridOption("loading", true);
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("GET", "FeatureFlags/Get", null, callback)
    }

    private saveData() {
        this._pageTools.toggleTriggers(this.shadowRoot!, true);
        const callback = (response: string, status: boolean) => {
            this._pageTools.toggleTriggers(this.shadowRoot!, false);
            if (!status) {
                this._alertMessage.showErrorMessage(response);
                return;
            }
            this._alertMessage.showSuccessMessage("Saved");
        }

        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("POST", "FeatureFlags/Update", { flags: this._data }, callback)
    }

    private saveClicked() {
        this.saveData();
    }

    private cancelClicked() {
        this.refreshData();
    }
}

customElements.define("feature-flag-management", FeatureFlagManagement);