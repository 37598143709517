import template from './RestrictIssuingAuthorityDialog.html';
import bootstrapTable from 'bootstrap-table/dist/bootstrap-table.css';
import 'bootstrap-table/dist/bootstrap-table';
import bootstrap from '../../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from '../HTMLElementExtensions';
import { createColumn } from '../BootstrapTableExtensions';

class RestrictIssuingAuthorityDialog extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap, bootstrapTable]);
        this._pageTools = new PageTools();
        this._authorityNameInput = this.shadowRoot.getElementById('authority-name');
        this._modal = this.shadowRoot.querySelector('modal-component');
        this._table = this.shadowRoot.querySelector("#table");
        this._buttonGroup = this.shadowRoot.querySelector('#button-group');
        this._saveButton = this._buttonGroup.querySelector('#restrict-issuing-authority-dialog-save-button');
        this._closeButton = this._buttonGroup.querySelector('#restrict-issuing-authority-dialog-close-button');
        this._errorMessage = this.shadowRoot.getElementById('error-message');
        this._form = this.shadowRoot.getElementById('ria-form');
        this._saveConfirmationDialog = this.shadowRoot.querySelector('save-restrict-issuing-authority-dialog-confirmation-dialog');

        this._saveRIA = this._saveRIA.bind(this);
        this._model = null;
        this._submitForm = this._submitForm.bind(this);
        this._closeModal = this._closeModal.bind(this);
        this._loadTableData = this._loadTableData.bind(this);
        this._compileColumns = this._compileColumns.bind(this);
    }

    connectedCallback() {
        this._saveButton.addEventListener('click', this._submitForm);
        this._closeButton.addEventListener('click', this._closeModal);
        this._saveConfirmationDialog.addEventListener('confirm', this._saveRIA);
    }

    disconnectedCallback() {
        this._saveButton.removeEventListener('click', this._submitForm);
        this._closeButton.removeEventListener('click', this._closeModal);
        this._saveConfirmationDialog.removeEventListener('confirm', this._saveRIA);
    }

    openModal(value) {
        this._riaUsers = value.riaUsers;
        this._model = this._makeModel(value.authUsers);
        this._operationType = value.operationType;
        this._endPoint = value.endPoint;
        this._title = value.title;
        this._errorMessage.textContent = "";
        this._authorityNameInput.value = value.riaUsers?.Name ? value.riaUsers?.Name : "";
        this._restrictedIssuingAuthorityId = value.riaUsers?.RestrictedIssuingAuthorityId ? value.riaUsers?.RestrictedIssuingAuthorityId : null;

        this._modal.openModal(this._title, false);
        this._loadTableData(this._sortTableData(this._model));
    }

    _loadTableData(model) {
        this._table.destroyTable();
        this._table.initTableData(
            {
                bootstrapTableConfig: {
                    tableColumns: this._compileColumns(),
                    classes: 'table table-sm table-striped table-bordered table-hover',
                    uniqueId: 'UniqueId',
                    onCheck: this._updateRowState.bind(this, true),
                    onCheckAll: this._onAllRowsCheckChanged.bind(this, true),
                    onUncheck: this._updateRowState.bind(this, false),
                    onUncheckAll: this._onAllRowsCheckChanged.bind(this, false),
                    checkboxHeader: true,
                    toolbar: false,
                    columnFilters: false,
                    sortable: true,
                    tableAlertMessage: false,
                    virtualScroll: false,
                    onPostBody: this._addEventsAfterTableLoad.bind(this)
                },
                tableData: model
            }
        );
    }

    _addEventsAfterTableLoad() {
        const rows = this._table.shadowRoot.querySelectorAll('tbody>tr');
        Array.from(rows).forEach((row) => {
            const limitCheckbox = this._getLimitCheckbox(row);
            const limitInput = this._getLimitInput(row);
            limitCheckbox.addEventListener('change', this._enforceLimitOnClick.bind(this, row));
            limitInput.addEventListener('blur', this._updateLimitInputOnBlur.bind(this, row));
        });
        this._setAsteriskVisibility();
    }

    _setAsteriskVisibility() {
        const rows = Array.from(this._table.shadowRoot.querySelectorAll('tbody>tr'));
        const asterisk = this._table.shadowRoot.querySelector('#asterisk');

        const anyRowRequired = rows.some(x => x.querySelector('td input').checked && this._getLimitCheckbox(x).checked);
        asterisk.toggleAttribute('hidden', !anyRowRequired);
    }

    _limitInputFormatter(value, row) {
        const authId = row.Auth0UserId.split('|')[1];
        const id = `limit-value-${authId}-${row.BondCompanyId}`;
        const valueAttr = row.Limit == null ? "" : `value="${row.Limit}"`;
        const disabledAttr = row.EnforceLimit && row.Enabled ? "" : "disabled";
        const requiredAttr = row.EnforceLimit && row.Enabled ? "required" : "";
        return `<money-input ${valueAttr} ${disabledAttr} ${requiredAttr} id="${id}" min='0.01' max='100000000000' placeholder="e.g. $1,000.00"></money-input>`;
    }

    _enforceLimitCheckboxFormatter(value, row) {
        const authId = row.Auth0UserId.split('|')[1];
        const bondCompanyId = row.BondCompanyId;
        let checkedAttr = value ? 'checked' : '';
        let disabledAttr = !row.Enabled ? 'disabled' : '';
        return `<span class="d-flex justify-content-center">` +
            `<input type='checkbox' ${checkedAttr} ${disabledAttr} id="enforce-limit-${authId}-${bondCompanyId}" class="input-style"/>` +
            `</span>`;
    }

    _updateRowState(checked, row) {
        this._updateModel('Enabled', checked, row.UniqueId);
        if (!checked) {
            this._updateModel('EnforceLimit', false, row.UniqueId);
            this._updateModel('Limit', null, row.UniqueId);
        } else {
            const rowElement = this._table.shadowRoot.querySelector(`tbody>tr[data-uniqueid="${row.UniqueId}"]`);
            this._enforceLimitOnClick(rowElement); // Handles wiring up the events propertly
        }
        this._setAsteriskVisibility();
    }

    _onAllRowsCheckChanged(checked, rowsAfter, rowsBefore) {
        if (checked) {
            rowsAfter.forEach(row => {
                this._updateRowState(checked, row);
            });
            return;
        }
        rowsBefore.forEach(row => {
            this._updateRowState(checked, row);
        });
    }

    _compileColumns() {
        const columns = [
            {
                field: 'Enabled',
                checkbox: true
            }
        ];
        const asterisk = '<span id="asterisk" class="text-danger" hidden>*</span>';

        columns.push(createColumn("User", "Name", undefined));
        columns.push(createColumn("Company", "CompanyName"));
        columns.push(createColumn(`Enforce Limit`, 'EnforceLimit', undefined, this._enforceLimitCheckboxFormatter.bind(this)));
        columns.push(createColumn(`Limit ${asterisk}`, 'Limit', undefined, this._limitInputFormatter.bind(this)));
        return columns;
    }

    _enforceLimitOnClick(row) {
        const uniqueId = row.getAttribute('data-uniqueid');
        let limitCheckbox = this._getLimitCheckbox(row);
        const checked = limitCheckbox.checked;
        this._updateModel('EnforceLimit', checked, uniqueId);
        if (!checked) {
            this._updateModel('Limit', null, uniqueId);
        } else {
            this._updateLimitInputOnBlur(row);
        }
        limitCheckbox = this._getLimitCheckbox(row);
        //Have to re-wire the event because _updateModel re-renders the cell and we lose the event
        limitCheckbox.addEventListener('change', this._enforceLimitOnClick.bind(this, row));
        this._setAsteriskVisibility();
    }
    
    _updateLimitInputOnBlur(row) {
        const uniqueId = row.getAttribute('data-uniqueid');
        let input = this._getLimitInput(row);
        const limitValue = isNaN(input.value) ? null : input.value;
        this._updateModel('Limit', limitValue, uniqueId);
        //Have to re-wire the event because _updateModel re-renders the cell and we lose the event
        input = this._getLimitInput(row);
        input.addEventListener('blur', this._updateLimitInputOnBlur.bind(this, row));
    }

    _submitForm() {
        if (!this._form.checkValidity()) {
            this._form.reportValidity();
            return;
        }
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest("GET",
            `/Admin/RestrictedIssuingAuthority/ChargesWithActiveAuthorityCheck?issuingAuthorityName=${encodeURIComponent(this._authorityNameInput.value)}`,
            null,
            this._newRIACallback.bind(this));
    }

    _newRIACallback(response, isSuccess) {
        if (!isSuccess) {
            this._errorMessage.textContent = response;
        }
        if (response === 'false') {
            this._pageTools.toggleTriggers(this.shadowRoot, false);
            this._saveConfirmationDialog.openModal({ title: this._authorityNameInput.value, hasCharges: response });
        } else {
            this._saveRIA();
        }
    }

    _saveRIA() {
        this._errorMessage.textContent = "";
        this._pageTools.toggleTriggers(this.shadowRoot, true);
        this._saveButton.setSpinner(true);
        const users = this._gatherData();
        const moneyInputs = Array.from(this._table.shadowRoot.querySelectorAll('money-input'));
        if (!moneyInputs.every(input => input.reportValidity())) {
            this._pageTools.toggleTriggers(this.shadowRoot, false);
            return;
        }
        const reqObject =
        {
            RestrictedIssuingAuthorityId: this._restrictedIssuingAuthorityId,
            Name: this._authorityNameInput.value,
            AuthorizedUsers: users.length,
            RestrictedIssuingAuthorityUsers: users
        };
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest('POST', this._endPoint, reqObject, this._responseCallback.bind(this));
    }

    _responseCallback(response, isSuccess) {
        if (!isSuccess) {
            this._errorMessage.textContent = response;
            this._pageTools.toggleTriggers(this.shadowRoot, false);
            return;
        }
        this._pageTools.toggleTriggers(this.shadowRoot, false);
        this._modal.closeModal();
        this.dispatchEvent(new CustomEvent(this._operationType === 'add' ? 'onModalAdd' : 'onModalEdit', { detail: { 'Name': this._authorityNameInput.value } }));
    }

    _gatherData() {
        return this._table.getSelections();
    }

    _makeModel(authUsers) {
        let model = authUsers.map((x, i) => ({
            UniqueId: i,
            Enabled: false,
            Auth0UserId: x.AuthUserId,
            BondCompanyId: x.BondCompanyId,
            CompanyName: x.CompanyName,
            Name: x.Name,
            EnforceLimit: false,
            Limit: null
        }));

        this._riaUsers?.RestrictedIssuingAuthorityUsers.forEach(x => {
            const item = model.find(y => (y.Auth0UserId === x.Auth0UserId && y.BondCompanyId === x.BondCompanyId));
            if (item === undefined) {
                return;
            }
            item.EnforceLimit = x.EnforceLimit;
            item.Limit = x.Limit;
            item.Enabled = true;
        });
        return model;
    }

    _updateModel(property, newValue, uniqueId) {
        this._table.updateCellByUniqueId({
            id: uniqueId,
            field: property,
            value: newValue,
            reinit: false
        });
    }

    _sortTableData(rows) {
        return rows.sort((a, b) => a.Name.localeCompare(b.Name) || a.CompanyName.localeCompare(b.CompanyName));
    }

    _getLimitCheckbox(row) {
        return row.querySelector('[id*="enforce-limit"]');
    }

    _getLimitInput(row) {
        return row.querySelector('[id*="limit-value"]');
    }

    _closeModal() {
        this._modal.closeModal();
    }
}
customElements.define('restrict-issuing-authority-dialog', RestrictIssuingAuthorityDialog);