import template from './CompanyFormModal.html';
import bootstrap from '../scss/bootstrap-custom.scss';
import { initializeHtmlElement } from './HTMLElementExtensions';
import companyType from './Enumerations/CompanyType';
import { sortSelectElementOptions } from './JavaScriptFunctions';

class CompanyFormModal extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap]);
        this._pageTools = new PageTools();
        this._tenantId = null;
        this._bondCompanyId = null;
        this._insuranceCompanies = null;
        this._enforceCollateralLimit = null;
        this._enforceOwnerBondCompanyCollateralLimit = null;
        this._form = this.shadowRoot.querySelector('form');
        this._modalComponent = this.shadowRoot.getElementById('company-form-modal-component');
        this._closeButton = this.shadowRoot.getElementById('closeModal-button');
        this._saveButton = this.shadowRoot.getElementById('company-action-button');
        this._formControlContainers = this.shadowRoot.querySelectorAll("#custom-modal section article");
        this.companyModel = null;
        this.editingCompany = false;
        this.openModal = this.openModal.bind(this);
        this.upsertCompany = this.upsertCompany.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this._enforceLimitChanged = this._enforceLimitChanged.bind(this);
        this._collateralLimitChanged = this._collateralLimitChanged.bind(this);
        this._companyTypeChanged = this._companyTypeChanged.bind(this);
        this._companySelect = this.shadowRoot.getElementById('CompanyType');
        this._attorneyOption = this.shadowRoot.getElementById('attorney-company');
        this._insuranceSelect = this.shadowRoot.getElementById('InsuranceCompanyID');
        this._physicalStateSelect = this.shadowRoot.getElementById('PStateCodeShort');
        this._mailingStateSelect = this.shadowRoot.getElementById('MStateCodeShort');
        this._ownerBirthDate = this.shadowRoot.getElementById('OwnerAgentDOB');
        this._companyName = this.shadowRoot.getElementById('CompanyName');
        this._companyEmail = this.shadowRoot.getElementById('CompanyEmail');
        this._companyPhone = this.shadowRoot.getElementById('CompanyPhone');
        this._electronicPOA = this.shadowRoot.getElementById('ElectronicPOA');
        this._physicalAddress = this.shadowRoot.getElementById('PhysicalAddr');
        this._physicalCity = this.shadowRoot.getElementById('PhysicalCity');
        this._physicalZip = this.shadowRoot.getElementById('PhysicalZip');
        this._mailingAddress = this.shadowRoot.getElementById('MailingAddr');
        this._mailingCity = this.shadowRoot.getElementById('MailingCity');
        this._mailingZip = this.shadowRoot.getElementById('MailingZip');
        this._license = this.shadowRoot.getElementById('License');
        this._licenseExpiration = this.shadowRoot.getElementById('LicenseExpiration');
        this._licenseNumberLabel = this.shadowRoot.getElementById("license-name");
        this._licenseExpirationLabel = this.shadowRoot.getElementById("expiration-name");
        this._ownerAgentName = this.shadowRoot.getElementById('OwnerAgentName');
        this._ownerAgentDOB = this.shadowRoot.getElementById('OwnerAgentDOB');
        this._isActive = this.shadowRoot.getElementById('isActive');
        this._collateralLimit = this.shadowRoot.getElementById('CollateralLimit');
        this._enforceLimit = this.shadowRoot.getElementById('EnforceLimit');
        this._availableCollateral = this.shadowRoot.getElementById('AvailableCollateral');
        this._alertMessage = this.shadowRoot.getElementById("alert-message");
        this._collateralLimitGroup = this.shadowRoot.querySelector('#collateralLimitGroup');
        this._collateralLimitRequired = this.shadowRoot.querySelector('#collateralLimitRequired');
        this._enforceLimitGroup = this.shadowRoot.querySelector('#enforceLimitGroup');
        this._availableCollateralGroup = this.shadowRoot.querySelector('#availableCollateralGroup');
        this._availableCollateralRequired = this.shadowRoot.querySelector('#availableCollateralRequired');
    }

    set tenantId(value) {
        this._tenantId = value;
    }

    set insuranceCompanies(value) {
        for (let item of value) {
            const option = document.createElement('option');
            option.value = item.InsuranceCompanyID;
            option.text = item.CompanyName;
            this._insuranceSelect.add(option);
        }
    }

    set enforceCollateralLimit(value) {
        this._enforceCollateralLimit = value;
    }

    set enforceOwnerBondCompanyCollateralLimit(value) {
        this._enforceOwnerBondCompanyCollateralLimit = value;
    }

    set attorneyCompaniesTemp(value) {
        this._attorneyOption.toggleAttribute('hidden', !value);
    }

    connectedCallback() {
        this._saveButton.addEventListener('click', this.upsertCompany);
        this._closeButton.addEventListener('click', this.closeModal);
        this._enforceLimit.addEventListener('change', this._enforceLimitChanged);
        this._collateralLimit.addEventListener('change', this._collateralLimitChanged);
        this._companySelect.addEventListener('change', this._companyTypeChanged);
    }

    disconnectedCallback() {
        this._saveButton.removeEventListener('click', this.upsertCompany);
        this._closeButton.removeEventListener('click', this.closeModal);
        this._enforceLimit.removeEventListener('change', this._enforceLimitChanged);
        this._collateralLimit.removeEventListener('change', this._collateralLimitChanged);
        this._companySelect.removeEventListener('change', this._companyTypeChanged);
    }

    closeModal() {
        this._alertMessage.clearMessage();
        this._modalComponent.closeModal();
    }

    openModal(name, model) {
        this._resetForm();
        sortSelectElementOptions(this._companySelect);
        sortSelectElementOptions(this._insuranceSelect);
        if (model) {
            this.editingCompany = true;
            this.companyModel = model;
            this._bondCompanyId = model.BondCompanyID;
            this._updateForm(this.companyModel);
        } else {
            this._bondCompanyId = 0;
            this._updateFormForCompanyType();
        }
        const title = name ?? 'New Company';
        this._modalComponent.openModal(title, false);
    }

    upsertCompany() {
        this._triggerState(true);
        const bondCompanyViewObject = this._createBondCompanyViewObject();
        const xhrWrapper = new XhrWrapper();
        xhrWrapper.makeRequest('POST',
            '/Admin/UpsertBondCompany',
            bondCompanyViewObject,
            this._formCallback.bind(this));
    }

    _adjustErrorMessage(message, formLabel, formValue) {
        if (message.includes("The value '' is invalid.") && formValue === '')
            return `The ${formLabel} field is required.`;

        return message;
    }

    _setTextInputValue(elem, value) {
        elem.value = this._checkCorrectDate(value);
    }

    _checkCorrectDate(data) {
        const date = moment(data?.toString(), ['YYYY-MM-DDTHH:mm:SS', 'YYYY-MM-DDTHH:mm:SS.SSS'], true);
        return (date.isValid()) ? date.format('MM/DD/YYYY') : data;
    }

    _formCallback(response, status) {
        this._triggerState(false);
        this._resetErrors();
        const result = this._pageTools.tryParseJson(response);
        if (!result && !status) {
            this._showErrorMessage();
            return;
        }
        if (!status) {
            this._validateForm((response) ? result : null);
            return;
        }
        this.dispatchEvent(new CustomEvent("refresh-company-grid", { "detail": result }));
        this.upsertAlertMessage = (this.editingCompany)
            ? `Successfully updated - ${result.find(x => x.BondCompanyID === this.companyModel.BondCompanyID)
            .CompanyName}`
            : "Successfully added a new bond company!";
        this._showAlert(this.upsertAlertMessage);
        this.closeModal();

        this.editingCompany = false;
    }

    _showAlert(message) {
        const alert = document.getElementById('alert-view');
        alert.showSuccessMessage(message);
    }

    _showErrorMessage() {
        this._alertMessage.showErrorMessage('Failed to save bond company information. Please try again. If the problem persists <contact-link>contact eBONDS™ Support</contact-link>.', true);
        this._alertMessage.scrollIntoView();
    }

    _removeAlert() {
        const alert = document.getElementById('alert-view');
        alert.clearMessage();
    }

    _resetErrors() {
        this._formControlContainers.forEach(x => {
            const errorLabel = x.querySelector('span.errors');
            errorLabel.innerText = '';
        });
    }

    _resetForm() {
        this._removeAlert();
        this._resetErrors();
        this.shadowRoot.querySelectorAll(`state-dropdown`).forEach(elem => {
            elem.value = "";
            elem.parentElement.toggleAttribute('hidden', false);
        });
        this.shadowRoot.querySelectorAll(`money-input`).forEach(elem => {
            elem.value = null;
            elem.toggleAttribute('disabled', false);
            elem.parentElement.toggleAttribute('hidden', false);
        });
        this.shadowRoot.querySelectorAll(`input[type="date"]`).forEach(elem => {
            elem.value = null;
        });
        this.shadowRoot.querySelectorAll(`input[type="email"]`).forEach(elem => {
            elem.value = null;
        });
        this.shadowRoot.querySelectorAll(`input[type="text"]`).forEach(elem => {
            elem.value = '';
            elem.parentElement.toggleAttribute('hidden', false);
        });
        this.shadowRoot.querySelectorAll(`input[type="checkbox"]`).forEach(elem => {
            elem.checked = false;
            elem.parentElement.parentElement.toggleAttribute('hidden', false);
        });
        this.shadowRoot.querySelectorAll(`select`).forEach(elem => {
            elem.value = '';
            elem.parentElement.toggleAttribute('hidden', false);
        });
        this.shadowRoot.querySelector(`#BondCompanyID`).value = '0';
    }

    _triggerState(state) {
        this.shadowRoot.querySelectorAll('[trigger]').forEach(x => x.disabled = state);
    }

    _formatDate(rowVal) {
        return moment(rowVal).format('YYYY-MM-DD');
    }


    _updateForm(model) {
        Object.entries(model).forEach(row => {
            const rowVal = row[1];
            const formField = this.shadowRoot.querySelector(`[id=${row[0]}]`);
            if (!formField)
                return;

            switch (formField.tagName.toLowerCase()) {
            case 'input':
                if (formField.getAttribute('type') === 'checkbox')
                    formField.checked = rowVal;
                else if (formField.getAttribute('type') === 'date')
                    formField.value = this._formatDate(rowVal);
                else
                    this._setTextInputValue(formField, rowVal);
                break;
            case 'select':
            case 'state-dropdown':
                this._setDropdownValue(rowVal, formField);
                break;
            case 'money-input':
                formField.value = rowVal;
                break;
            }
        });

        if (model.CompanyType === companyType.county.value) {
            const hiddenFields = Array.from(this.shadowRoot.querySelectorAll('label'))
                .filter(el => el.textContent.includes('Physical'));
            hiddenFields.push(this._physicalStateSelect);
            hiddenFields.push(this._insuranceSelect);
            hiddenFields.push(this._companySelect);
            hiddenFields.push(this._electronicPOA.parentElement);
            hiddenFields.push(this._collateralLimit);
            hiddenFields.forEach(el => el.parentElement.toggleAttribute('hidden', true));
        }

        this._updateFormForCompanyType();
    }

    _setDropdownValue(rowVal, formField) {
        if (rowVal === null || rowVal === undefined)
            formField.value = '';
        else
            formField.value = rowVal;
    }

    _updateFormForCompanyType() {
        let selectedCompanyType = parseInt(this._companySelect.value);
        if (Number.isNaN(selectedCompanyType)) { // Since the drop down doesn't have a value, we special case this to make logic down below easier.
            selectedCompanyType = companyType.county.value;
        }

        this._electronicPOA.parentElement.parentElement.toggleAttribute('hidden', selectedCompanyType !== companyType.surety.value);

        const hideOwnerEnforceLimitFields = (selectedCompanyType !== companyType.owner.value && selectedCompanyType !== companyType.attorney.value) || !this._enforceOwnerBondCompanyCollateralLimit;

        this._enforceLimitGroup.toggleAttribute('hidden', hideOwnerEnforceLimitFields);
        this._availableCollateralGroup.toggleAttribute('hidden', hideOwnerEnforceLimitFields);

        if (!hideOwnerEnforceLimitFields) {
            this._enforceLimitChanged();
            this._collateralLimitChanged();
        } else {
            // These fields may be shown, and thus need to be un-disabled
            this._collateralLimit.toggleAttribute('disabled', false);
            this._availableCollateral.toggleAttribute('disabled', false);
        }

        // If we're hiding owner enforce limit fields
        const hideCollateralLimitField = hideOwnerEnforceLimitFields && (selectedCompanyType === companyType.county.value || !this._enforceCollateralLimit);
        
        this._collateralLimitGroup.toggleAttribute('hidden', hideCollateralLimitField);

        this._updatePropertyForAttorneyCompany();
    }

    _validateForm(errors) {
        this._formControlContainers.forEach(x => {
            const errorSpan = x.querySelector('span.errors');
            if (errorSpan.hasAttribute('data-valmsg-for')) {

                const formName = errorSpan.getAttribute('data-valmsg-for');
                if (formName in errors) {
                    const formLabel = x.querySelector(`label[for='${formName}'`);
                    const formElement = x.querySelector(`[id='${formName}']`);
                    errorSpan.innerText =
                        this._adjustErrorMessage(errors[formName][0], formLabel.textContent, formElement.value);
                }
            }
        });
    }

    _enforceLimitChanged() {
        const disable = !this._enforceLimit.checked;
        this._collateralLimit.toggleAttribute('disabled', disable);
        this._collateralLimitRequired.toggleAttribute('hidden', disable);
        this._availableCollateral.toggleAttribute('disabled', disable);
        this._availableCollateralRequired.toggleAttribute('hidden', disable);
    }

    _collateralLimitChanged() {
        this._availableCollateral.setAttribute('max', this._collateralLimit.value);
    }

    _companyTypeChanged() {
        this._updateFormForCompanyType();
        this._updatePropertyForAttorneyCompany();
    }

    _updatePropertyForAttorneyCompany() {
        let selectedCompanyType = parseInt(this._companySelect.value);
        if (selectedCompanyType == companyType.attorney.value) {
            this._licenseNumberLabel.textContent = "Bar Number";
            this._licenseExpirationLabel.textContent = "Bar Expiration";
        }
        else {
            this._licenseNumberLabel.textContent = "License";
            this._licenseExpirationLabel.textContent = "License Expiration";
        }
    }

    _createBondCompanyViewObject() {
        const viewObject = {
            BondCompanyID: this._bondCompanyId,
            CompanyName: this._companyName.value,
            CompanyEmail: this._companyEmail.value,
            CompanyPhone: this._companyPhone.value,
            CompanyType: (this.companyModel?.CompanyType === companyType.county.value) ? companyType.county.value : this._companySelect.value,
            ElectronicPOA: this._electronicPOA.checked,
            PhysicalAddr: this._physicalAddress.value,
            PhysicalCity: this._physicalCity.value,
            PStateCodeShort: this._physicalStateSelect.value,
            PhysicalZip: this._physicalZip.value,
            MailingAddr: this._mailingAddress.value,
            MailingCity: this._mailingCity.value,
            MStateCodeShort: this._mailingStateSelect.value,
            MailingZip: this._mailingZip.value,
            License: this._license.value,
            LicenseExpiration: this._licenseExpiration.value,
            OwnerAgentName: this._ownerAgentName.value,
            OwnerAgentDOB: this._ownerAgentDOB.value,
            isActive: this._isActive.checked,
            CollateralLimit: this._collateralLimit.value,
            EnforceLimit: this._enforceLimit.checked,
            AvailableCollateral: this._availableCollateral.value,
            InsuranceCompanyID: this._insuranceSelect.value,
            InsuranceCompany: {
                InsuranceCompanyID: this._insuranceSelect.value,
                CompanyName: this._insuranceSelect.options[this._insuranceSelect.selectedIndex]?.text
            },
            TenantId: this._tenantId

        };
        return viewObject;
    }
}

customElements.define('company-form-modal', CompanyFormModal);