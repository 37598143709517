import { ICellEditorComp, ICellEditorParams } from "ag-grid-enterprise";
import "../MoneyInput";
import type { MoneyInput } from "../MoneyInput";

export interface ICurrencyCellEditorParams extends ICellEditorParams {
    max?: number;
    min?: number;
}

export class CurrencyCellEditor implements ICellEditorComp {
    gui!: MoneyInput;
    params!: ICurrencyCellEditorParams;

    init(params: ICurrencyCellEditorParams) {
        this.gui = <MoneyInput>document.createElement('money-input');
        this.gui.toggleAttribute("data-use-ag-grid-styles", true);
        this.params = params;
        this.gui.max = params.max ?? null;
        this.gui.min = params.min ?? null;
        this.gui.value = params.value;
        this.gui.setAttribute("tabindex", "0"); // make focusable
    }

    getGui(): HTMLElement {
        return this.gui;
    }

    getValue() {
        return this._getTrueCellValue(this.gui.value, this.params.max, this.params.min);
    }

    afterGuiAttached() {
        this.gui.focus();
    }

    private _getTrueCellValue(guiVal: number | null, maxVal: number | undefined, minVal: number | undefined ): number | null {
        if (minVal !== undefined && guiVal !== null && guiVal < minVal) {
            return minVal;
        } else if (maxVal !== undefined && guiVal !== null && guiVal > maxVal){
            return maxVal;
        } else
            return guiVal;
    }
}
