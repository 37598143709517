import bootstrap from '../scss/bootstrap-custom.scss';
import template from './MoneyInput.html';
import { initializeHtmlElement } from './HTMLElementExtensions';
import { nullThrow } from "./TypeScriptFunctions";

// How to make a custom element form associated
// https://web.dev/more-capable-form-controls/
// https://html.spec.whatwg.org/multipage/custom-elements.html#custom-elements-face-example

export class MoneyInput extends HTMLElement {
    private readonly _inputElement: HTMLInputElement;
    private readonly _pageTools: IPageTools;
    private readonly _internals: ElementInternals;
    private _min: number | null = null;
    private _max: number | null = null;

    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap], [], true);

        this._inputElement = nullThrow(this.shadowRoot?.querySelector('#money-input'));
        this._pageTools = new PageTools();
        this._internals = this.attachInternals();
        this._inputElementOnChange = this._inputElementOnChange.bind(this);
    }

    static get observedAttributes(): string[] {
        return ['required', 'value', 'min', 'max', 'disabled', 'placeholder', 'not-allowed-cursor', "data-use-ag-grid-styles"];
    }

    static get formAssociated(): boolean {
        return true;
    }

    reportValidity(): boolean {
        return this._inputElement.reportValidity();
    }

    connectedCallback() {
        this._inputElement.addEventListener('change', this._inputElementOnChange);
    }

    disconnectedCallback() {
        this._inputElement.removeEventListener('change', this._inputElementOnChange);
    }

    attributeChangedCallback(name: string, oldValue: string, newValue: string | null) {
        if (oldValue === newValue)
            return;

        switch (name) {
            case 'required':
                this._inputElement.toggleAttribute('required', newValue !== null);
                this._setValidity();
                break;
            case 'value':
                this.value = newValue === null ? null : Number(newValue);
                break;
            case 'min':
                this.min = newValue === null ? null : Number(newValue);
                break;
            case 'max':
                this.max = newValue === null ? null : Number(newValue);
                break;
            case 'disabled':
                this._inputElement.disabled = newValue !== null;
                break;
            case 'placeholder':
                this._inputElement.placeholder = newValue ?? "";
                break;
            case 'not-allowed-cursor':
                this._inputElement.style.cursor = "not-allowed";
                break;
            case "data-use-ag-grid-styles":
                if (newValue !== null)
                    this._appyAgGridStyles();
                else
                    this._removeAgGridStyles();
                break;
        }
    }

    set value(value: number | null) {
        if (value !== null && value.toString() !== '')
            this._inputElement.value = this._pageTools.formatNumberToDollarAmount(this._parseValue(value));
        else
            this._inputElement.value = "";
        this._setValidity();
    }

    set min(value: number | null) {
        this._min = value;
        this._setValidity();
    }

    set max(value: number | null) {
        this._max = value;
        this._setValidity();
    }

    get value(): number | null {
        return this._parseValue(this._inputElement.value);
    }

    get min(): number | null {
        return this._min;
    }

    get max(): number | null {
        return this._max;
    }

    private _appyAgGridStyles() {
        this._inputElement.classList.remove("form-control");
        this._inputElement.classList.add("currency-editor");
    }

    private _removeAgGridStyles() {
        this._inputElement.classList.add("form-control");
        this._inputElement.classList.remove("currency-editor");
    }

    private _inputElementOnChange() {
        if ((this.min !== null && this.value === null) || (this.min !== null && this.value !== null && this.value < this.min)) {
            this.value = this.min;
        }

        if (this.max !== null && this.value !== null && this.value > this.max) {
            this.value = this.max;
        }

        // enforce formatting
        this.value = this._parseValue(this._inputElement.value);

        this._setValidity();
        this.dispatchEvent(new Event('change'));
    }

    private _setValidity() {
        this._internals.setValidity(this._inputElement.validity, this._inputElement.validationMessage, this._inputElement);
    }

    private _parseValue(value: number | string): number | null {
        const result = parseFloat(`${value}`.replace(/[^\d.-]/g, ""));
        if (Number.isNaN(result))
            return null;
        return result;
    }
}

customElements.define('money-input', MoneyInput);