import { nullThrow } from "../TypeScriptFunctions";
import { ActionButton } from "../ActionButton";
import type { ICellRendererComp, ICellRendererParams } from "ag-grid-enterprise";

export interface ButtonRendererParams<TData> {
    value: ButtonRendererValue<TData>
}

export interface ButtonRendererValue<TData> {

    buttons: {
        onClick: (params: ICellRendererParams<TData, ButtonRendererValue<TData>>, e: MouseEvent) => void,
        text: string,
        color: "btn-primary" | "btn-secondary" | "btn-success" | "btn-danger" | "btn-warning" | "btn-info" | "btn-light" | "btn-dark" | "btn-link" | undefined,
        disabled?: boolean
    }[]
    style?: string
}

export class ButtonRenderer<TData> implements ICellRendererComp {
    private element: HTMLElement | undefined;

    init(params: ICellRendererParams<TData, ButtonRendererValue<TData>>): void {
        if (params.colDef === undefined) {
            params.colDef = {};
        }
        params.colDef.editable = false;

        const value = nullThrow(params.value);
        const container = document.createElement("span");
        value.buttons.forEach(buttonParams => {
            const button = <ActionButton>document.createElement("action-button");
            const classes = `btn btn-x-sm ${buttonParams.color ?? "btn-primary"}`;
            button.classList.add(...classes.split(' '));
            button.addEventListener("click", (e) => buttonParams.onClick(params, e));
            // Prevent ag-grid events from triggering when clicking the button
            button.addEventListener("mousedown", (e) => e.stopPropagation());
            button.textContent = buttonParams.text;
            button.disabled = buttonParams.disabled ?? false;
            container.appendChild(button);
        });

        container.style.cssText = "display: flex; align-items: center; justify-content: right; height: 100%; gap: .5em";
        if (value.style !== undefined) {
            container.style.cssText += value.style; // Allow for styles to be overridden but don't replace everything
        }

        this.element = container;
    }

    getGui(): HTMLElement {
        return nullThrow(this.element);
    }

    refresh(params: ICellRendererParams<any, any, any>): boolean {
        this.init(params);
        // If we want to re-use this component, we should cleanup things, set new values, and return true. But thats more work than I want to do right now.
        return true;
    }
}